import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps';
import VuejsPaginate from 'vuejs-paginate';

var popUpHtmlString = "<div class='locator-select'><img src='{4}' alt='street view image'><h5><svg class='select {0}' width='10px' height='50px' viewBox='0 0 8 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-56.000000, -520.000000)' fill='#C30019' fill-rule='nonzero'><g transform='translate(0.000000, 166.000000)'><polygon points='56 354 63.5 369 56 369'></polygon></g></g></g></svg> {1} </h5><p> {2} {3} away</p></div>";

var streetViewImageFormat = "https://maps.googleapis.com/maps/api/streetview?size=167x100&location={0} {1} {2} {3} {4}&key=AIzaSyALictSRsnD9JdL60NvTDlZOhWgJb4F6Ck";

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyALictSRsnD9JdL60NvTDlZOhWgJb4F6Ck',
        libraries: 'places'
    }
});

export default {
    components: {
        'paginate': VuejsPaginate
    },
    data: function () {
        return {
            center: {
                lat: 32.82767,
                lng: -97.05026
            },
            pagerPrevText: "<span class='fa fa-caret-left' aria-hidden='true'></span>",
            pagerNextText: "<span class='fa fa-caret-right' aria-hidden='true'></span>",
            radius: 100,
            pageSize: 8,
            currentPage: 1,
            locationType: "ALL",
            initialLatitude: "",
            initialLongitude: "",
            currentLatitude: "",
            currentLongitude: "",
            avgLat: 0,
            avgLng: 0,
            zipCode: '00000',
            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                },
                maxWidth: 200
            },
            selectedFilter: null,
            showLocationWindow: false,
            totalPages: 0,
            totalLocations: 0,
            markers: [],
            locationResults: [],
            paginate: ['locations'],
            mapLoaded: false,
            showFilterType: false,
            zipCodeValue: "",
            getLocationsUri: "/api/locations/get-locations?currentPage={0}&pageSize={1}&radius={2}&zipCode={3}&latitude={4}&longitude={5}&locationType={6}"
        };
    },
    methods: {
        toggleInfoWindow: function (marker, idx) {
            console.log(`toggleInfoWindow(${marker}, ${idx})`);

            this.infoWindowPos = marker.position;
            this.infoContent = marker.infoText;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx === idx) {
                // this.infoWinOpen = !this.infoWinOpen; //test
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }

            this.$refs.mapRef.panTo(marker.position);
        },

        moreFiltersClick: function () {
            this.showFilterType = !this.showFilterType;
        },

        locationItemHover: function (locationId, idx) {
            if (locationId !== null && locationId !== 'undefined') {

                var marker = this.markers.filter(
                    function (marker) {
                        return marker.id === locationId.toString();
                    }
                )[0];

                if (marker !== null && marker !== undefined && marker !== 'undefined') {
                    this.toggleInfoWindow(marker, idx);
                }
            }
        },

        locationItemClick: function (locationId, idx) {
            if (locationId !== null && locationId !== 'undefined') {

                $(this.$el).find('.locator-details .locator-item').hide();

                setTimeout(() => $(this.$el).find('.locator-details .locator-item[data-location-id=' + locationId + ']').fadeIn(500), 500);

                this.showLocationWindow = true;

            } else {
                this.showLocationWindow = false;
            }
        },

        clickPagination: function (pageNum) {
            this.currentPage = pageNum;
            this.fetchLocations(this.currentLatitude, this.currentLongitude);

            $('#branchresults').animate({
                scrollTop: 0
            }, 1000);
        },

        zipCodeSubmit: function (event) {
            this.zipCode = this.zipCodeValue;
            this.currentPage = 1;
            this.totalPages = 0;
            this.currentLatitude = "";
            this.currentLongitude = "";

            this.fetchLocations(this.currentLatitude, this.currentLongitude);
        },

        zipCodeClick: function() {
            this.zipCodeSubmit(this.zipCodeValue);
        },

        nearMeSearch: function (event) {
            this.currentLatitude = this.initialLatitude;
            this.currentLongitude = this.initialLongitude;
            $('#locations-search').val('');

            this.fetchLocations(this.currentLatitude, this.currentLongitude);
        },

        locationChange: function (event) {
            var $targetElement = event.currentTarget;
            var locationType = $targetElement.getAttribute('data-location-type');
            this.locationType = locationType;
            this.currentPage = 1;
            this.totalPages = 0;
            this.selectedFilter = $targetElement.getAttribute('data-location-text');
            this.showFilterType = false;
            this.fetchLocations(this.currentLatitude, this.currentLongitude);
        },

        MapMarkers: function (locations) {

            var image = {
                url: '/Assets/images/icons/pointer.png',
                size: new google.maps.Size(30, 32),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(2, 34)
            };

            var bounds = new google.maps.LatLngBounds();

            for (var i = 0; i < locations.length; i++) {
                var lat = this.getValueByKey("Latitude", locations[i].attributes);
                var long = this.getValueByKey("Longitude", locations[i].attributes);

                if (lat !== '' && long !== '') {
                    var locationName = this.getValueByKey("LocationName", locations[i].attributes);
                    var distance = locations[i].distance;
                    var distanceUnit = locations[i].distanceUnit;

                    var marker = {};

                    var streetViewImage = this.stringFormat(streetViewImageFormat, locationName, locations[i].address.street, locations[i].address.city, locations[i].address.state, locations[i].address.zip);
                    marker.infoText = this.stringFormat(popUpHtmlString, !locations[i].mainBranch ? "d-none" : "", locationName, distance, distanceUnit, streetViewImage);
                    marker.position = {};
                    marker.position.lat = parseFloat(lat);
                    marker.position.lng = parseFloat(long);
                    marker.id = locations[i].id;
                    marker.icon = image;
                    this.markers.push(marker);

                    var loc = new google.maps.LatLng(marker.position.lat, marker.position.lng);
                    bounds.extend(loc);
                }
            }

            this.$refs.mapRef.fitBounds(bounds); // auto - zoom
            this.$refs.mapRef.panToBounds(bounds); // auto - center
        },

        MapLocations: function (locations) {
            for (var i = 0; i < locations.length; i++) {
                var locationResult = {};
                locationResult.id = locations[i].id;
                locationResult.mainBranch = locations[i].mainBranch;
                locationResult.locationName = this.getValueByKey("LocationName", locations[i].attributes);
                locationResult.locationSummary = this.getValueByKey("RetailOutlet", locations[i].attributes);
                locationResult.phone = "tel:" + this.getValueByKey("Phone", locations[i].attributes);
                locationResult.phoneNumber = this.getValueByKey("Phone", locations[i].attributes);
                locationResult.mapUrl = locations[i].mapUrl;
                locationResult.addressLine = locations[i].address.street;
                locationResult.city = locations[i].address.city;
                locationResult.state = locations[i].address.state;
                locationResult.zipCode = locations[i].address.zip;
                locationResult.distance = locations[i].distance;
                locationResult.distanceUnit = locations[i].distanceUnit;
                locationResult.locationTypes = locations[i].locationTypes;
                locationResult.additionalDetails = locations[i].additionalDetails;
                locationResult.hourLabels = locations[i].hourLabels;
                locationResult.hourValues = locations[i].hourValues;

                this.locationResults.push(locationResult);
            }
        },

        stringFormat: function () {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "gm");
                s = s.replace(reg, arguments[i + 1]);
            }

            return s;
        },

        getValueByKey: function (key, attributes) {
            var keyValuePair = attributes.filter(
                function (property) { return property.key === key }
            )[0];

            if (keyValuePair !== null && keyValuePair !== undefined && keyValuePair !== 'undefined') {
                return keyValuePair.text;
            } else {
                return '';
            }
        },

        emptyMarkersAndLocations: function () {
            this.markers = [];
            this.locationResults = [];
            this.avgLat = 0;
            this.avgLng = 0;
        },

        browserGeolocationSuccess: function (position) {
            this.initialLatitude = position.coords.latitude;
            this.currentLatitude = position.coords.latitude;
            this.initialLongitude = position.coords.longitude;
            this.currentLongitude = position.coords.longitude;
            this.fetchLocations(this.currentLatitude, this.currentLongitude);
        },

        browserGeolocationFail: function (error) {
            this.fetchLocations("", "");
        },

        fetchLocations: function (lat, long) {
            this.$refs.mapRef.$mapPromise.then(() => {

                var getLocationUri = '';

                if (lat !== "" && long !== "") {
                    getLocationUri = this.stringFormat(this.getLocationsUri, this.currentPage, this.pageSize, this.radius, this.zipCode, lat, long, this.locationType);
                } else {
                    getLocationUri = this.stringFormat(this.getLocationsUri, this.currentPage, this.pageSize, this.radius, this.zipCode, "", "", this.locationType);
                }

                axios.get(getLocationUri).then(response => {

                    var locations = response.data.locations;
                    this.totalLocations = response.data.totalCount;
                    this.totalPages = Math.ceil(response.data.totalCount / this.pageSize);

                    this.emptyMarkersAndLocations();
                    this.MapMarkers(locations);
                    this.MapLocations(locations);
                    this.mapLoaded = true;

                }, error => {
                    console.error(error);
                });

                //this.mapLoaded = true;
            });
        }
    },
    mounted: function () {
        this.radius = this.$refs.locationSearch.dataset.radius;
        this.pageSize = this.$refs.locationSearch.dataset.pagesize;
        this.zipCode = this.$refs.locationSearch.dataset.zipcode;

        // Try HTML5 geolocation.
        if (!(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Windows Phone/i) || navigator.appVersion.indexOf('Trident/') > -1) && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.browserGeolocationSuccess, this.browserGeolocationFail, { maximumAge: 50000, timeout: 20000, enableHighAccuracy: true });
        } else {
            this.fetchLocations("", "");
        }
    }
};
