import axios from 'axios';
import VuejsPaginate from 'vuejs-paginate';

export default {
    components: {
        'paginate': VuejsPaginate
    },
    data: function () {
        return {
            blogs: [],
            categories: [],
            moreCategories: [],
            selectedCategory: "0",
            selectedCategoryValue: "",
            pagerString: "",
            pagerPrevText: "<span class='fa fa-caret-left' aria-hidden='true'></span>",
            pagerNextText: "<span class='fa fa-caret-right' aria-hidden='true'></span>",
            pageSize: 6,
            currentPage: 1,
            totalPages: 0,
            totalBlogs: 0,
            resultCharLimit: 0,
            paginate: ['blogs'],
            blogsLoaded: false,
            categoriesLoaded: false,
            showFilterType: false,
            moreCategoriesActive: false,
            active_el: 0,
            $blogCategoryDropdown: null,
            getBlogsUri: "/api/search/getBlogs?category={0}&currentPage={1}&pageSize={2}&resultCharLimit={3}"
        };
    },
    methods: {
        moreFiltersClick: function () {
            this.showFilterType = !this.showFilterType;
            this.moreCategoriesActive = this.showFilterType;
        },

        getBlogs: function () {

            var getBlogsUri = this.stringFormat(this.getBlogsUri, this.selectedCategory, this.currentPage, this.pageSize, this.resultCharLimit);

            axios.get(getBlogsUri).then(response => {
                this.blogs = response.data.Blogs;
                this.totalBlogs = response.data.Pager.TotalItems;
                this.totalPages = Math.ceil(response.data.Pager.TotalItems / this.pageSize);
                this.pagerString = response.data.PagerString;

                if (!this.categoriesLoaded) {
                    this.categories = response.data.Categories;
                    this.moreCategories = response.data.MoreCategories;

                    this.$blogCategoryDropdown = $('.select-list-dropdown-blogcategory').prettyDropdown({
                        customClass: 'dropdown form-dropdown arrow'
                    });
                }

                this.categoriesLoaded = true;

            }, error => {
                console.error(error);
            });

            this.blogsLoaded = true;
        },

        setCategory: function (category) {
            this.selectedCategory = category.Id;
            this.selectedCategoryValue = "";
            this.moreCategoriesActive = false;
            this.currentPage = 1;
            this.active_el = category.Id;

            this.getBlogs();
        },

        setMobileCategory: function (category) {
            var categoryObj = {};
            categoryObj.Id = category;
            this.setCategory(categoryObj);
        },

        setMoreCategory: function (category) {
            this.selectedCategory = category.Id;
            this.selectedCategoryValue = category.Value;
            this.currentPage = 1;
            this.active_el = category.Id;

            this.getBlogs();
            this.moreFiltersClick();
            this.moreCategoriesActive = true;
        },

        clickPagination: function (pageNum) {
            this.currentPage = pageNum;
            this.getBlogs();

            $('.blog-listing-block').animate({
                scrollTop: 0
            }, 1000);
        },

        stringFormat: function () {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "gm");
                s = s.replace(reg, arguments[i + 1]);
            }

            return s;
        },

        getValueByKey: function (key, attributes) {
            var keyValuePair = attributes.filter(
                function (property) { return property.key === key }
            )[0];

            if (keyValuePair !== null && keyValuePair !== undefined && keyValuePair !== 'undefined') {
                return keyValuePair.text;
            } else {
                return '';
            }
        }
    },
    mounted: function () {
        this.pageSize = this.$refs.blogSearch.dataset.pagesize;
        this.selectedCategory = this.$refs.blogSearch.dataset.blogcategory;
        this.active_el = this.$refs.blogSearch.dataset.blogcategory;
        this.resultCharLimit = this.$refs.blogSearch.dataset.charlimit;
        this.getBlogs();
    },
    updated: function () {
        this.$nextTick(function () {
            if (this.$blogCategoryDropdown !== null && this.$blogCategoryDropdown !== undefined) {
                this.$blogCategoryDropdown.refresh();
            }
        });
    },
    computed: {
        groupedBlogItems() {
            var grouped = [];
            var index = -1;
            for (let i = 0; i < this.blogs.length; i++) {
                if (i % 3 === 0) {
                    index++;
                    grouped[index] = [];
                }
                grouped[index].push(this.blogs[i]);
            }
            return grouped;
        },

        groupedMoreCategories() {
            var grouped = [];
            var index = -1;
            for (let i = 0; i < this.moreCategories.length; i++) {
                if (i % 6 === 0) {
                    index++;
                    grouped[index] = [];
                }
                grouped[index].push(this.moreCategories[i]);
            }
            return grouped;
        }
    }
};
