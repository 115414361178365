
export default {
    data: function() {
        return {
            query: "",
            autocompleteResults: [],
            showAutocomplete: false
        };
    },
    methods: {
        submitSearch: function(searchUrl) {
            var vm = this;

            window.location.href = searchUrl + "?q=" + encodeURIComponent(vm.query);
        },
        autocomplete: function () {
            var vm = this;

            vm.autocompleteResults = [];

            //if (vm.query.length > 1) {
            //    $.ajax({
            //        url: '/api/search/autocomplete',
            //        dataType: "json",
            //        type: 'Get',
            //        data: {
            //            term: vm.query,
            //            size: 4
            //        },
            //        success: function (data) {
            //            vm.autocompleteResults = data.Data;
            //        }
            //    });

            //    vm.showAutocomplete = true;
            //}
        },
        autocompleteSearch: function (term, searchUrl) {
            var vm = this;

            vm.query = term;
            vm.submitSearch(searchUrl);
        },
        handleClickOutside(evt) {
            var vm = this;

            if (!vm.$el.contains(evt.target)) {
                vm.showAutocomplete = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};