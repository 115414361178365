Vue.directive('bgsrcset', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
       
        var item = {};

        var update = function () {
            let src = typeof item.img.currentSrc !== 'undefined' ? item.img.currentSrc : item.img.src;
            if (item.src !== src) {
                item.src = src;
                item.element.style.backgroundImage = 'url("' + item.src + '")';

            }
        };

        item.element = el;
        item.img = el.querySelector('img');
        item.src = '';

        item.img.addEventListener('load', function () {
            update();
        });

        if (item.img.complete) {
            update();
        }
    }
});