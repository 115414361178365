$(document).ready(function() {
    $('#photo-upload').on('click', function (e) {
        $('#PhotoFile').click();
        e.preventDefault();
    });

    $('#PhotoFile').change(function () {
        var fileName = $('#PhotoFile')[0].files[0].name;

        $('#photo-file-name').text(fileName);
    });
});