$(document).ready(function() {
    $('input:radio[name="AssignmentType"]').change(function() {
        if ($(this).val() === '1') {
            $('#topic1-doc-upload').show();
            $('#topic1-video-upload').hide();
            $('#topic2-doc-upload').show();
            $('#topic2-video-upload').hide();

            $('.allowed-file-types.variable span').text("Allowed extensions: .doc, .docx, and .pdf");
        } else if ($(this).val() === '2') {
            $('#topic1-doc-upload').hide();
            $('#topic1-video-upload').show();
            $('#topic2-doc-upload').hide();
            $('#topic2-video-upload').show();

            $('.allowed-file-types.variable span').text("Allowed extensions: .mp4, .mov, and .wmv");
        }
    });

    $('#topic1-doc-upload').on('click', function (e) {
        $('#Topic1DocFile').click();
        e.preventDefault();
    });
    $('#topic1-video-upload').on('click', function (e) {
        $('#Topic1VideoFile').click();
        e.preventDefault();
    });
    $('#topic2-doc-upload').on('click', function (e) {
        $('#Topic2DocFile').click();
        e.preventDefault();
    });
    $('#topic2-video-upload').on('click', function (e) {
        $('#Topic2VideoFile').click();
        e.preventDefault();
    });
    $('#resume-upload').on('click', function (e) {
        $('#ResumeFile').click();
        e.preventDefault();
    });

    $('#ResumeFile').change(function() {
        var fileName = $('#ResumeFile')[0].files[0].name;

        $('#resume-file-name').text(fileName);
    });
    $('#Topic1DocFile').change(function () {
        var fileName = $('#Topic1DocFile')[0].files[0].name;

        $('#topic-1-file-name').text(fileName);
    });
    $('#Topic1VideoFile').change(function () {
        var fileName = $('#Topic1VideoFile')[0].files[0].name;

        $('#topic-1-file-name').text(fileName);
    });
    $('#Topic2DocFile').change(function () {
        var fileName = $('#Topic2DocFile')[0].files[0].name;

        $('#topic-2-file-name').text(fileName);
    });
    $('#Topic2VideoFile').change(function () {
        var fileName = $('#Topic2VideoFile')[0].files[0].name;

        $('#topic-2-file-name').text(fileName);
    });
});