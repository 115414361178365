$(document).ready(function() {
    $("#personal-dob").change(function () {
        var dob = $(this).val();
        var today = new Date();
        var day = Number(dob.substring(3, 5));
        var month = Number(dob.substring(0, 2));
        var year = Number(dob.substring(6));
        var age = today.getFullYear() - year;
        var thisMonth = today.getMonth() + 1;
        var thisDay = today.getDate();

        if (thisMonth < month || thisMonth === month && thisDay < day) {
            age--;
        }

        if (age < 18) {
            $("#parental-auth-delivery-section").show();

            if ($("#upload-delivery:checked").length) {
                $("#parental-auth-upload-section").show();
            } else if ($("#parental-auth-upload-section").hasClass("show-admin")) {
                $("#parental-auth-upload-section").show();
            }
        } else {
            $("#parental-auth-delivery-section").hide();
            $("#parental-auth-upload-section").hide();
        }
    });

    $("input[name='DeliveryType']").change(function() {
        if ($(this).val() == 1) {
            $("#parental-auth-upload-section").show();
        } else if (!$("#parental-auth-upload-section").hasClass("show-admin")) {
            $("#parental-auth-upload-section").hide();
        }
    });

    $('#parental-release-upload').on('click', function (e) {
        $('#ParentalReleaseFile').click();
        e.preventDefault();
    });

    $('#ParentalReleaseFile').change(function () {
        var fileName = $('#ParentalReleaseFile')[0].files[0].name;

        $('#parental-file-name').text(fileName);
    });
});