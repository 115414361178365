$(document).ready(function() {
    $('#reference-upload').on('click', function (e) {
        $('#ReferenceFile').click();
        e.preventDefault();
    });

    $('#ReferenceFile').change(function () {
        var fileName = $('#ReferenceFile')[0].files[0].name;

        $('#reference-file-name').text(fileName);
    });
});