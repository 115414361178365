$(document).ready(function() {
    $('#reference1-upload').on('click', function (e) {
        $('#Reference1File').click();
        e.preventDefault();
    });

    $('#reference2-upload').on('click', function (e) {
        $('#Reference2File').click();
        e.preventDefault();
    });

    $('#Reference1File').change(function () {
        var fileName = $('#Reference1File')[0].files[0].name;

        $('#reference1-file-name').text(fileName);
    });

    $('#Reference2File').change(function () {
        var fileName = $('#Reference2File')[0].files[0].name;

        $('#reference2-file-name').text(fileName);
    });

    $('.resend-request-link').on('click', function () {
        var appId = $(this).data('app-id');
        var referenceNumber = $(this).data('reference-number');

        var data = { AppId: appId, ReferenceNumber: referenceNumber };

        $.ajax({
            url: "/api/customForm/resendReference",
            dataType: 'json',
            type: 'Post',
            data: data,
            success: function (response) {
                if (response.Success) {
                    if (referenceNumber == 1) {
                        $('#reference1-success-message').text("Request Sent");
                    } else if (referenceNumber == 2) {
                        $('#reference2-success-message').text("Request Sent");
                    }
                } else {
                    if (response.ErrorType === 12) {
                        $('#references-error-message').text("First and last name must be provided before sending emails");
                    } else {
                        $('#references-error-message').text("An error occured attempting to resend email. Please try again.");
                    }
                }
            },
            error: function (error, exception) {
                console.log("An error occurred");
            }
        });
    });

    $('.delete-reference-link').on('click', function() {
        var appId = $(this).data('app-id');
        var referenceNumber = $(this).data('reference-number');

        var data = { AppId: appId, ReferenceNumber: referenceNumber };

        $.ajax({
            url: "/api/customForm/deleteReference",
            dataType: 'json',
            type: 'Post',
            data: data,
            success: function (success) {
                if (success) {
                    window.location.reload();
                }
            },
            error: function (error, exception) {
                console.log("An error occurred");
            }
        });
    });
});