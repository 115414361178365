var scores = [];

$(document).ready(function () {
    
    initializeScores();
    
    $('.scoring-tabs a').on('click', function () {
        $('.scoring-tabs a').removeClass('active');

        $(this).addClass('active');
    });

    $('#scoring-form-tab').on('click', function () {
        $('.scoring-section .form-container').hide();

        $('#main-scoring').show();
    });

    $('#offline-scoring-tab').on('click', function () {
        $('.scoring-section .form-container').hide();

        $('#offline-scoring').show();
    });

    $('.select-form-input').change(function (e) {
        var name = $(e.target).attr('name');
        var score = $(e.target).val();
        var scoreObject = new Object();
        scoreObject.name = name;
        scoreObject.score = score;
        pushToArray(scores, scoreObject);
        updateScore();
    });
});

function pushToArray(arr, obj) {
    const index = arr.findIndex((e) => e.name === obj.name);

    if (index === -1) {
        arr.push(obj);
    } else {
        arr[index] = obj;
    }
}

function initializeScores() {
    var result;
    try {
        result = JSON.parse($('#saved-scores').val());
    }
    catch (e) {
        result = [];
    }

    scores = result;
    updateScore();
}

function updateScore() {
    var totalScore = 0;

    scores.forEach(function (element) {
        var intVal = parseInt(element.score);
        if (Number.isInteger(intVal)) {
            totalScore = totalScore + intVal;
        }
    });

    $('#total-score').text(totalScore);
    $('#online-score').val(totalScore);
    $('#saved-scores').val(JSON.stringify(scores));

}