import VuejsPaginate from 'vuejs-paginate';
import axios from 'axios';

export default {
    components: {
        'paginate': VuejsPaginate
    },
    data: function () {
        return {
            query: "",
            prevQuery: "",
            results: [],
            page: 1,
            autocompleteResults: [],
            showAutocomplete: false,
            pagerPrevText: "<span class='fa fa-caret-left' role='button' title='previous page'></span>",
            pagerNextText: "<span class='fa fa-caret-right' title='next page'></span>",
            totalPages: 0,
            resultsLoaded: false
        };
    },
    methods: {
        search: function (fromForm, searchUrl, pageSize) {
            var vm = this;

            if (fromForm) {
                window.location.href = searchUrl + "?q=" + encodeURIComponent(vm.query);
            } else {
                vm.results = [];
                vm.prevQuery = vm.query;

                var getSearchUri = this.stringFormat("/api/search/getResults?query={0}&page={1}", vm.query, vm.page);
                axios.get(getSearchUri).then(response => {

                    console.log(response.data);

                    vm.results = response.data.Results;
                    vm.totalPages = response.data.TotalPages;
                    this.resultsLoaded = true;

                }, error => {
                    console.error(error);
                });

                //$.ajax({
                //    url: '/api/search/getResults',
                //    dataType: "json",
                //    type: 'Get',
                //    data: {
                //        query: vm.query,
                //        page: vm.page
                //    },
                //    success: function (data) {
                //        vm.results = data.Data.Results;
                //        vm.totalPages = data.Data.TotalPages;
                //        this.resultsLoaded = true;
                //    }
                //});
            }
        },
        stringFormat: function () {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "gm");
                s = s.replace(reg, arguments[i + 1]);
            }

            return s;
        },
        autocomplete: function () {
            var vm = this;

            vm.autocompleteResults = [];

            //if (vm.query.length > 1) {
            //    $.ajax({
            //        url: '/api/search/autocomplete',
            //        dataType: "json",
            //        type: 'Get',
            //        data: {
            //            term: vm.query,
            //            size: 4
            //        },
            //        success: function (data) {
            //            vm.autocompleteResults = data.Data;
            //        }
            //    });

            //    vm.showAutocomplete = true;
            //}
        },
        autocompleteSearch: function (term, searchUrl) {
            var vm = this;

            vm.query = term;
            vm.search(true, searchUrl);
        },
        handleClickOutside(evt) {
            var vm = this;

            if (!vm.$el.contains(evt.target)) {
                vm.showAutocomplete = false;
            }
        },
        clickPagination: function (pageNum) {
            var vm = this;

            vm.page = pageNum;
            vm.search();
        }
    },
    mounted: function () {
        var vm = this;

        document.addEventListener('click', vm.handleClickOutside);

        var queryIndex = location.search.indexOf('?q=');
        var queryString = location.search.slice(queryIndex + 3, location.search.length);

        if (queryString.indexOf('&') !== -1) {
            queryString = queryString.slice(0, queryString.indexOf('&'));
        }

        vm.query = decodeURIComponent(queryString).replace(/&#39;/g, "\'").replace(/&quot;/g, "\"").replace(/&amp;/g, "&");

        if (vm.query !== "") {
            vm.search();
        }
    },
    destroyed: function () {
        document.removeEventListener('click', this.handleClickOutside);
    }
}
