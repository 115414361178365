import SmartBanner from 'smart-app-banner';
import '@iconfu/svg-inject';

function getLocation(href) {
    var location = document.createElement("a");
    location.href = href;
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (location.host === "") {
        location.href = location.href;
    }
    return location;
}




$(document).ready(function () {
    $('.select-list-dropdown').prettyDropdown({
        customClass: 'dropdown form-dropdown arrow'
    });

    if ($('#advisorHiddenEmail') !== undefined && $('#advisorHiddenEmail').val() !== undefined && $('input[name=__field_2107]') !== '') {
        $('input[name=__field_2107]').val($('#advisorHiddenEmail').val());
    }

    if ($('#facebookAppId') !== undefined && $('#facebookAppId').val() !== undefined) {
        FB.init({
            appId: $('#facebookAppId').val(),
            status: true,
            xfbml: true,
            version: 'v3.2'
        });
    }


    var $shareBtn = document.getElementById('shareBtn');
    if ($shareBtn) {
        $shareBtn.onclick = function () {
            var url = $('#absolutePageUrl').val();

            FB.ui({
                method: 'share',
                display: 'popup',
                href: url
            },
                function (response) {
                    console.log(response);
                });
        };
    }

    $('a').on('click', function (e) {
        if ($(this).attr('href') === undefined) {
            return true;
        }

        var modal = $('#aacu-exit-modal');
        if (!modal.length) {
            return true;
        }

        var internalDomains = JSON.parse($('#internal-domains').text());
        var location = getLocation(this.href);
        if (internalDomains !== null && internalDomains !== undefined) {
            var internalDomain = internalDomains.includes(location.hostname);
            var phoneNumber = this.href.startsWith("tel:");
            if (internalDomain || phoneNumber) {
                return true;
            }
        }

        e.preventDefault();

        $('#continue-exit-btn').data('url', $(this).attr('href'));
        modal.modal('show');
    });

    $('svg.password-icon').on('click', function () {
        var input = $('#online-banking-login-form input[name=password], #mobile-banking-login input[name=password]');
        input.each(function () {
            if ($(this).attr('type') === "password") {
                $(this).attr('type', "text");
            } else {
                $(this).attr('type', "password");
            }
        });

    });

    $('#online-banking-login').on('click', function (event) {
        var events = $._data(document, 'events') || {};
        events = events.click || [];
        for (var i = 0; i < events.length; i++) {
            if (events[i].selector) {

                //Check if the clicked element matches the event selector
                if ($(event.target).is(events[i].selector)) {
                    events[i].handler.call(event.target, event);
                }

                // Check if any of the clicked element parents matches the 
                // delegated event selector (Emulating propagation)
                $(event.target).parents(events[i].selector).each(function () {
                    events[i].handler.call(this, event);
                });
            }
        }
        event.stopPropagation();
    });

    $('#continue-exit-btn').on('click', function () {
        $('#aacu-exit-modal').modal('hide');

        window.location.href = $('#continue-exit-btn').data('url');
    });

    $('.nav-header-1').hover(function () {
        $("nav[data-column='column-1'] > a").addClass('nav-active');
    }, function () {
        $("nav[data-column='column-1'] > a").removeClass('nav-active');
    });

    $('.nav-header-1').on('click', function () {
        $('.header-column-link').removeClass('selected-col');
        $("nav[data-column='column-1'] > a").addClass('selected-col');
    });


    $('.chat-trigger').click(function (e) {
        e.preventDefault();
        $('#LP_DIV_1415126912798').find('div').trigger('click');
    });

    $('.chat-trigger').bind('keyup', function (e) {

        if (e.keyCode === 13) { // 13 is enter key
            $('#LP_DIV_1415126912798').find('div').trigger('click');
        }
    });

    $('.nav-header-2').hover(function () {
        $("nav[data-column='column-2'] > a").addClass('nav-active');
    }, function () {
        $("nav[data-column='column-2'] > a").removeClass('nav-active');
    });

    $('.nav-header-2').on('click', function () {
        $('.header-column-link').removeClass('selected-col');
        $("nav[data-column='column-2'] > a").addClass('selected-col');
    });

    $('.nav-header-3').hover(function () {
        $("nav[data-column='column-3'] > a").addClass('nav-active');
    }, function () {
        $("nav[data-column='column-3'] > a").removeClass('nav-active');
    });

    $('.nav-header-3').on('click', function () {
        $('.header-column-link').removeClass('selected-col');
        $("nav[data-column='column-3'] > a").addClass('selected-col');
    });

    $('.nav-header-4').hover(function () {
        $("nav[data-column='column-4'] > a").addClass('nav-active');
    }, function () {
        $("nav[data-column='column-4'] > a").removeClass('nav-active');
    });

    $('.nav-header-4').on('click', function () {
        $('.header-column-link').removeClass('selected-col');
        $("nav[data-column='column-4'] > a").addClass('selected-col');
    });

    $('.nav-header-5').hover(function () {
        $("nav[data-column='column-5'] > a").addClass('nav-active');
    }, function () {
        $("nav[data-column='column-5'] > a").removeClass('nav-active');
    });

    $('.nav-header-5').on('click', function () {
        $('.header-column-link').removeClass('selected-col');
        $("nav[data-column='column-5'] > a").addClass('selected-col');
    });

    $('.online-banking-login-button, .category-header').bind('keyup', function (e) {
        if (e.keyCode === 13) { // 13 is enter key
            $(this).trigger('click');
        }
    });

    $('.nav-header a').bind('keyup', function (e) {
        if (e.keyCode === 13) { // 13 is enter key
            $(this).trigger('click');

            var links = Array.from(document.querySelectorAll('.nav-menu nav a'));

            links.forEach(function (el) {
                el.tabIndex = -1;
            });

            var index = $(this).data("column-header");
            links = Array.from(document.querySelectorAll(".nav-menu nav"));

            links.forEach(function (el) {
                var col = "column-" + index;
                if (el.dataset.column === col) {
                    var items = Array.from(el.querySelectorAll("a"));

                    items.forEach(function (item) {
                        item.tabIndex = index + 1;
                    });

                    items[0].focus();
                }
            });
        }
    });

    $('.nav-header a').on("focus", function () {
        if ($('#navpanel').css('display') !== 'none') {
            $(this).trigger('click');
        }
    });

    $('#chat-trigger').on("focus", function () {
        if ($('#navpanel').css('display') !== 'none') {
            var x = document.getElementById("navpanel");
            var spans = document.getElementsByClassName('nav-header-text');
            var headers = document.querySelectorAll('.nav-header a');

            Array.from(headers).forEach(function (e) {
                e.setAttribute("aria-expanded", "false");
            });

            Array.from(spans).forEach(function (el) {
                el.classList.remove('selected');
            });

            x.style.display = "none";
        }
    });

    $(document).on('click', function (e) {
        var target = e.target;
        var panel = document.getElementById('navpanel');
        var labels = document.getElementsByClassName('nav-header');
        var isLabel = false;

        Array.from(labels).forEach(function (label) {
            if (!isLabel) {
                isLabel = target === label || label.contains(target);
            }
        });

        var contains = panel !== null && panel.contains(target);

        if (target !== panel && !isLabel && !contains) {
            $('#navpanel').slideUp();
        }
    });

    $(document).on('scroll', function (e) {
        $('#navpanel').slideUp();
    });

    /* Product rate section javascript start */
    $(".rate-section:first").addClass('open');
    var openOnLoad = $(".rate-section:first").next('.collapsing-section');
    $(openOnLoad).slideDown();

    $(".rate-section").bind('keyup', function (e) {
        if (e.keyCode === 13) { // 13 is enter key
            $(this).click();
        }
    });

    $(".rate-section").on('mousedown', function () {
        $(this).data("mouseDown", true);
    });

    $(".rate-section").on('mouseup', function () {
        this.blur();

        $(this).removeData("mouseDown");
    });

    $(".rate-section").on('click', function (e) {
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).next('.collapsing-section').slideUp();
        } else {
            $(this).addClass('open');
            $(this).next('.collapsing-section').slideDown();
        }
    });
    /* Product rate section javascript end */

    $('.comparison-mobile .mobile-col-header-container .comparison-mobile-col-header').on('click', function (e) {
        var activeTabs = $('.comparison-mobile .mobile-col-header-container .comparison-mobile-col-header.active');

        activeTabs.removeClass('active');

    });

    $('.content-tab-mobile-index span[role=tab]').on('click', function (e) {
        var activeTabs = $(this).parents('.content-tab-mobile-index').find('span[role=tab].active');
        activeTabs.removeClass('active');
    });

    $(".numberTextBox").on('keypress', function () {
        var keyCode = event.keyCode;
        if (keyCode >= 48 && keyCode <= 57) {
            return true;
        } else {
            return false;
        }
    });


    $('.form-validate').submit(function (e) {
        var validationPassed = true;
        $(this).find(':input.required').each(function () {
            var empty = $(this).val() === "" || $(this).val() === null;
            if (empty) {
                validationPassed = false;
                $(this).addClass('invalid');
            }
        });

        if (validationPassed) {
            return true;
        }

        e.preventDefault();
        return false;
    });

    $('.custom-datepicker').each(function() {
        $(this).datepicker({
            beforeShow: function (input, inst) {
                $('#ui-datepicker-div').removeClass("month-year-picker");
            },
            constrainInput: false,
            dateFormat: "mm/dd/yy",
            changeYear: true,
            yearRange: "-100:+0"
        }).on('change', function () {
            $('.ui-datepicker').hide();
        });
    });

    $(".monthpicker").each(function () {
        $(this).datepicker({
            beforeShow: function (input, inst) {
                $('#ui-datepicker-div').removeClass("month-year-picker");
                $('#ui-datepicker-div').addClass("month-year-picker");
            },
            dateFormat: 'mm/yy',
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true
        }).focus(function () {
            var picker = $(this);
            $('.ui-datepicker-close').click(function () {
                var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                picker.datepicker('setDate', new Date(year, month, 1));
            });
        });
    });

    $('.toast-close').on('click', function () {
        $(this).closest('.toast-message-modal').hide();
    });

});


if (window.location.href.indexOf("nochrome") === -1) {
    //smart banner initialization
    new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        title: 'American Airlines Credit Union',
        author: 'AA Federal Credit Union',
        button: 'VIEW',
        store: {
            ios: 'On the App Store',
            android: 'In Google Play',
            windows: 'In Windows store'
        },
        price: {
            ios: 'FREE',
            android: 'FREE',
            windows: 'FREE'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        //, force: 'ios' // Uncomment for platform emulation
    });
}
