//imports
import 'babel-polyfill';
import 'bootstrap';
import 'webpack-jquery-ui';
import 'webpack-jquery-ui/css';
//import 'jquery-ui/ui/widgets/datepicker';
//import 'jquery-ui/themes/base/all.css';
import 'pretty-dropdowns';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import './common';

//includes
import './includes/toggleButton';

//components
import BranchLocator from './components/BranchLocator';
import MainSearch from './components/mainSearch';
import BlogSearch from './components/blogSearch';
import HeaderSearch from './components/headerSearch';
import EventsSearch from './components/eventLanding';
import ScholarshipAdminDashboard from './components/scholarshipAdminDashboard';
import ScholarshipReviewerDashboard from './components/scholarshipReviewerDashboard';
import './components/sendMessage';
import './components/forms';
import './components/customForms';
import './components/personalInfo';
import './components/scholarshipReferences';
import './components/fileUpload';
import './components/academics';
import './components/scholarshipAssignments';
import './components/additionalQuestions';
import './components/referenceUpload';
import './components/scholarshipAdminDetails';
import './components/scholarshipReviewerDetails';

//directives
import './directives/bgsrcset';


Vue.config.silent = true;

//vue initialization
var app = new Vue({
    el: '#app',
    components: {
        'branch-locator': BranchLocator,
        'main-search': MainSearch,
        'blog-search': BlogSearch,
        'events-search': EventsSearch,
        'header-search': HeaderSearch,
        'admin-dashboard': ScholarshipAdminDashboard,
        'reviewer-dashboard': ScholarshipReviewerDashboard
    }
});

//blog category change
$(".select-list-dropdown-month").change(function (e) {
    app.$refs.eventSearch.setMonth(e, $(this).val());
});

$(".select-list-dropdown-category").change(function () {
    app.$refs.eventSearch.setCategory($(this).val());
});

$(".select-list-dropdown-blogcategory").change(function () {
    app.$refs.blogSearch.setMobileCategory($(this).val());
});


