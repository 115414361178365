$(document).ready(function() {
    $('#show-sat').change(function() {
        if (this.checked) {
            $('#sat-section').show();
        } else {
            $('#sat-section').hide();
        }
    });

    $('#show-act').change(function () {
        if (this.checked) {
            $('#act-section').show();
        } else {
            $('#act-section').hide();
        }
    });

    $('#show-unweighted').change(function () {
        if (this.checked) {
            $('#unweighted-section').show();
        } else {
            $('#unweighted-section').hide();
        }
    });

    $('#show-weighted').change(function () {
        if (this.checked) {
            $('#weighted-section').show();
        } else {
            $('#weighted-section').hide();
        }
    });
});