import axios from 'axios';

export default {
    data: function () {
        return {
            pendingResults: [],
            scoredResults: [],
            getPendingApplicationsUri: "/api/get/applicationsForReviewer",
            idOrder: "asc",
            firstNameOrder: "",
            lastNameOrder: "",
            statusOrder: "",
            schoolOrder: "",
            scoreOrder: ""
        };
    },
    methods: {
        

        sortPending: function (column) {
            var vm = this;

            if (column === "Id") {
                if (vm.idOrder === "asc") {
                    vm.idOrder = "dsc";
                } else if (vm.idOrder === "dsc" || vm.idOrder === "") {
                    vm.idOrder = "asc";
                }

                vm.pendingResults.sort(vm.compare("ScholarshipApplicationId", vm.idOrder));
            } else if (column === "FirstName") {
                if (vm.firstNameOrder === "asc") {
                    vm.firstNameOrder = "dsc";
                } else if (vm.firstNameOrder === "dsc" || vm.firstNameOrder === "") {
                    vm.firstNameOrder = "asc";
                }

                vm.pendingResults.sort(vm.compare("FirstName", vm.firstNameOrder));
            } else if (column === "LastName") {
                if (vm.lastNameOrder === "asc") {
                    vm.lastNameOrder = "dsc";
                } else if (vm.lastNameOrder === "dsc" || vm.lastNameOrder === "") {
                    vm.lastNameOrder = "asc";
                }

                vm.pendingResults.sort(vm.compare("LastName", vm.lastNameOrder));
            } else if (column === "School") {
                if (vm.schoolOrder === "asc") {
                    vm.schoolOrder = "dsc";
                } else if (vm.schoolOrder === "dsc" || vm.schoolOrder === "") {
                    vm.schoolOrder = "asc";
                }

                vm.shownResults.sort(vm.compare("School", vm.schoolOrder));
            } else if (column === "Status") {
                if (vm.statusOrder === "asc") {
                    vm.statusOrder = "dsc";
                } else if (vm.statusOrder === "dsc" || vm.statusOrder === "") {
                    vm.statusOrder = "asc";
                }

                vm.pendingResults.sort(vm.compare("Status", vm.statusOrder));
            }
        },

        sortScored: function (column) {
            var vm = this;

            if (column === "Id") {
                if (vm.idOrder === "asc") {
                    vm.idOrder = "dsc";
                } else if (vm.idOrder === "dsc" || vm.idOrder === "") {
                    vm.idOrder = "asc";
                }

                vm.scoredResults.sort(vm.compare("ApplicationId", vm.idOrder));
            } else if (column === "FirstName") {
                if (vm.firstNameOrder === "asc") {
                    vm.firstNameOrder = "dsc";
                } else if (vm.firstNameOrder === "dsc" || vm.firstNameOrder === "") {
                    vm.firstNameOrder = "asc";
                }

                vm.scoredResults.sort(vm.compare("FirstName", vm.firstNameOrder));
            } else if (column === "LastName") {
                if (vm.lastNameOrder === "asc") {
                    vm.lastNameOrder = "dsc";
                } else if (vm.lastNameOrder === "dsc" || vm.lastNameOrder === "") {
                    vm.lastNameOrder = "asc";
                }

                vm.scoredResults.sort(vm.compare("LastName", vm.lastNameOrder));
            } else if (column === "School") {
                if (vm.schoolOrder === "asc") {
                    vm.schoolOrder = "dsc";
                } else if (vm.schoolOrder === "dsc" || vm.schoolOrder === "") {
                    vm.schoolOrder = "asc";
                }

                vm.scoredResults.sort(vm.compare("School", vm.schoolOrder));
            } else if (column === "Score") {
                if (vm.scoreOrder === "asc") {
                    vm.scoreOrder = "dsc";
                } else if (vm.scoreOrder === "dsc" || vm.scoreOrder === "") {
                    vm.scoreOrder = "asc";
                }

                vm.scoredResults.sort(vm.compare("Score", vm.scoreOrder));
            }
        },

        compare: function (column, order) {
            return function (a, b) {
                var result = 0;

                if (!a.hasOwnProperty(column) || !b.hasOwnProperty(column)) {
                    // property doesn't exist on either object
                    return 0;
                }

                var itemA = (typeof a[column] === 'string') ? a[column].toUpperCase() : a[column];
                var itemB = (typeof b[column] === 'string') ? b[column].toUpperCase() : b[column];

                if ((itemA === null || itemA === "") && (itemB !== null && itemB !== "")) {
                    result = 1;
                } else if ((itemA !== null && itemA !== "") && (itemB === null || itemB === "")) {
                    result = -1;
                } else if (itemA > itemB) {
                    result = 1;
                } else if (itemB > itemA) {
                    result = -1;
                }

                return order === "dsc" ? result * -1 : result;
            };
        }
    },
    mounted: function () {
        var vm = this;

        axios.get(vm.getPendingApplicationsUri)
            .then(response => {
                vm.pendingResults = response.data.PendingApplications;
            
                if (vm.pendingResults === null) {
                    vm.pendingResults = [];
                }

                vm.scoredResults = response.data.ScoredApplications;

                if (vm.scoredResults === null) {
                    vm.scoredResults = [];
                }
            }, error => {
                console.error(error);
            });
    }
};