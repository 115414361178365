$(document).ready(function () {
    var sendMessage = function (e, formData) {
        $.ajax({
            url: '/api/webhook/emailmessage',
            dataType: "json",
            type: 'Post',
            headers: {
                'Accept': 'application/json'
            },
            data: formData,
            success: function (data) {
                $(e).hide();
                if (data) {
                    $('#send-message-success').show();
                } else {
                    $('#send-message-error').show();
                }
            },
            error: function (error, exception) {
                $(e).hide();
                $('#send-message-error').show();
            }
        });
    };

    var submitForm = function(e) {
        var formData = $(e).serialize();

        $("#send-message-block .validation-message").hide();
        var formValues = formData.split('&');
        var validationPassed = true;
        var formDictionary = {};

        formValues.forEach(function(item) {
            var keyValue = item.split('=');

            if (keyValue[0] === "Name" && keyValue[1] === "") {
                $("#name-validation-message").html("Name is required");
                $("#name-validation-message").show();
                $("#name-validation-message-mobile").html("Name is required");
                $("#name-validation-message-mobile").show();
                validationPassed = false;
            }
            if (keyValue[0] === "Email" && keyValue[1] === "") {
                $("#email-validation-message").html("Email is required");
                $("#email-validation-message").show();
                $("#email-validation-message-mobile").html("Email is required");
                $("#email-validation-message-mobile").show();
                validationPassed = false;
            }
            if (keyValue[0] === "Description" && keyValue[1] === "") {
                $("#description-validation-message").html("Description is required");
                $("#description-validation-message").show();
                $("#description-validation-message-mobile").html("Description is required");
                $("#description-validation-message-mobile").show();
                validationPassed = false;
            }

            formDictionary[keyValue[0]] = keyValue[1];
        });

        if ($.inArray("Agree%20to%20Promotions=true", formValues) === -1) {
            $("#agree-validation-message").html("Must agree to be contacted");
            $("#agree-validation-message").show();
            $("#agree-validation-message-mobile").html("Must agree to be contacted");
            $("#agree-validation-message-mobile").show();
            validationPassed = false;
        }

        if (!("State" in formDictionary) || formDictionary["State"] === "") {
            $("#state-validation-message").html("State is required");
            $("#state-validation-message").show();
            $("#state-validation-message-mobile").html("State is required");
            $("#state-validation-message-mobile").show();
            validationPassed = false;
        }

        if ("g-recaptcha-response" in formDictionary && formDictionary["g-recaptcha-response"] !== "") {
            $.ajax({
                url: '/api/webhook/recaptchaVerify',
                headers: {
                    'Accept': 'application/json'
                },
                dataType: "json",
                type: 'Post',
                data: { "response": formDictionary["g-recaptcha-response"] },
                success: function (data) {
                    if (data !== true) {
                        $('#recaptcha-validation-message').html("Confirm you are not a robot");
                        $('#recaptcha-validation-message').show();
                        $('#recaptcha-validation-message-mobile').html("Confirm you are not a robot");
                        $('#recaptcha-validation-message-mobile').show();
                        validationPassed = false;
                    }

                    if (validationPassed) {
                        sendMessage(e, formData);
                    }
                },
                error: function (error, exception) {
                    $(e).hide();
                    $('#send-message-error').show();
                    validationPassed = false;
                }
            });
        } else {
            $('#recaptcha-validation-message').html("Confirm you are not a robot");
            $('#recaptcha-validation-message').show();
            $('#recaptcha-validation-message-mobile').html("Confirm you are not a robot");
            $('#recaptcha-validation-message-mobile').show();
            validationPassed = false;

            if (validationPassed) {
                sendMessage(e, formData);
            }
        }

        
    }

    $('#send-message-form-desktop').submit(function (e) {
        e.preventDefault();

        submitForm(this);
    });


    $('#send-message-form-mobile').submit(function (e) {
        e.preventDefault();

        submitForm(this);
    });
});