import VuejsPaginate from 'vuejs-paginate';
import axios from 'axios';
import fz from 'fuzzaldrin-plus';
import download from "downloadjs";

export default {
    components: {
        'paginate': VuejsPaginate
    },
    data: function () {
        return {
            results: [],
            filteredResults: [],
            shownResults: [],
            filteredTeams: [],
            query: "",
            getApplicantsUri: "/api/get/applications",
            getTeams1Uri: "/api/get/teams/1",
            getTeams2Uri: "/api/get/teams/2",
            getTeams3Uri: "/api/get/teams/3",
            setTeamUri: "/api/add/teams",
            removeTeamUri: "/api/remove/teams",
            updateRoundsUri: "/api/update/roundByApplication",
            page: 1,
            pagerPrevText: "<span class='fa fa-caret-left' role='button' title='previous page'></span>",
            pagerNextText: "<span class='fa fa-caret-right' title='next page'></span>",
            pageSize: 10,
            totalPages: 0,
            resultsLoaded: false,
            allApplicants: true,
            round: 0,
            rowsSelected: false,
            selectedApplications: [],
            educationFilterShown: false,
            educationFilters: [],
            statusFilterShown: false,
            statusFilters: [],
            teamFilterShown: false,
            teamFilters: [],
            assignedTeams: [],
            showTeamAssign: false,
            removedTeams: [],
            showTeamRemoval: false,
            assignedRounds: [],
            removedRounds: [],
            teams: [],
            showRoundAssign: false,
            showRoundRemove: false,
            idOrder: "asc",
            firstNameOrder: "",
            lastNameOrder: "",
            statusOrder: "",
            memberOrder: "",
            transcriptOrder: "",
            referencesOrder: "",
            typeOrder: "",
            schoolOrder: "",
            teamsOrder: "",
            scoreOrder: "",
            roundsOrder: "",
            json_fields: {
                'Scholarship Application ID': 'ScholarshipApplicationId',
                'Email Address': 'Email',
                'First Name': 'FirstName',
                'Last Name': 'LastName',

                // Personal Section
                'Birth Date': 'Personal.Data.BirthdateFormatted',
                'Parental Release Delivery Type': {
                    field: 'Personal.Data.ParentalReleaseDelivery',
                    callback: (value) => {
                        if (value === 1) {
                            return "Upload";
                        }
                        if (value === 2) {
                            return "Mail";
                        }

                        return "";
                    }
                },
                'Parental Release Received': 'Personal.Data.ParentalReleaseReceived',
                'School Type': {
                    field: 'Personal.Data.SchoolType',
                    callback: (value) => {
                        if (value === 1) {
                            return "High School";
                        }
                        if (value === 2) {
                            return "College";
                        }

                        return "";
                    }
                },
                'Graduation Date': 'Personal.Data.GraduationDateFormatted',
                'Address 1': 'Personal.Data.Address1',
                'Address 2': 'Personal.Data.Address2',
                'City': 'Personal.Data.City',
                'State': 'Personal.Data.State',
                'Zip Code': 'Personal.Data.PostalCode',
                'Phone Number': 'Personal.Data.PhoneNumber',
                'Previous Winner': 'Personal.Data.PreviousWinner',
                'Member Last Four': 'Personal.Data.MemberLastFourSSN',
                'Active Member': 'Personal.Data.ActiveMember',
                'Employee Dependent': 'Personal.Data.EmployeeDependent',
                'Member Verified': {
                    field: 'Personal.Data.MemberVerified',
                    callback: (value) => {
                        if (value) {
                            return true;
                        }

                        return false;
                    }
                },
                'Personal Section Status': {
                    field: 'Personal.Status',
                    callback: (value) => {
                        if (value === 0) {
                            return "Not Started";
                        }
                        if (value === 1) {
                            return "Draft";
                        }
                        if (value === 2) {
                            return "Complete";
                        }

                        return "";
                    }
                },

                // References Section
                'References Status': {
                    field: 'References.Status',
                    callback: (value) => {
                        if (value === 0) {
                            return "Not Started";
                        }
                        if (value === 1) {
                            return "Draft";
                        }
                        if (value === 2) {
                            return "References Requested";
                        }
                        if (value === 3) {
                            return "References Received";
                        }

                        return "";
                    }
                },
                'Reference 1 Name': 'References.Data.Reference1Name',
                'Reference 1 Email': 'References.Data.Reference1Email',
                'Reference 1 Code': 'References.Data.Reference1Code',
                'Reference 1 File Recieved': 'References.Data.Reference1FileRecieved',
                'Reference 1 File Received Date': 'References.Data.Reference1ReceivedDate',
                'Reference 2 Name': 'References.Data.Reference2Name',
                'Reference 2 Email': 'References.Data.Reference2Email',
                'Reference 2 Code': 'References.Data.Reference2Code',
                'Reference 2 File Recieved': 'References.Data.Reference2FileRecieved',
                'Reference 2 File Received Date': 'References.Data.Reference2ReceivedDate',

                // Transcript Section
                'Transcript Requested': 'Transcript.TranscriptRequested',
                'Transcript Recieved': {
                    field: 'Transcript.TranscriptRecieved',
                    callback: (value) => {
                        if (value) {
                            return true;
                        }

                        return false;
                    }
                },
                'Transcript Status': {
                    field: 'Transcript.Status',
                    callback: (value) => {
                        if (value === 0) {
                            return "Not Started";
                        }
                        if (value === 1) {
                            return "Transcript Requested";
                        }
                        if (value === 2) {
                            return "Transcript Recieved";
                        }

                        return "";
                    }
                },

                // Academics Section
                'College Name': 'Academics.Data.CollegeName',
                'College Major': 'Academics.Data.CollegeMajor',
                'Colleg GPA Score': 'Academics.Data.CollegGPAScore',
                'Colleg GPA Scale': 'Academics.Data.CollegGPAScale',
                'High School Name': 'Academics.Data.HighSchoolName',
                'Has SAT': 'Academics.Data.HasSAT',
                'Has ACT': 'Academics.Data.HasACT',
                'Has Weighted GPA': 'Academics.Data.HasWeightedGPA',
                'Has Unweighted GPA': 'Academics.Data.HasUnweightedGPA',
                'SAT Score': 'Academics.Data.SATScore',
                'SAT Scale': 'Academics.Data.SATScale',
                'ACT Score': 'Academics.Data.ACTScore',
                'Unweighted GPA Score': 'Academics.Data.UnweightedGPAScore',
                'Unweighted GPA Scale': 'Academics.Data.UnweightedGPAScale',
                'Weighted GPA Score': 'Academics.Data.WeightedGPAScore',
                'Weighted GPA Scale': 'Academics.Data.WeightedGPAScale',
                'Planned College Name': 'Academics.Data.PlannedCollegeName',
                'Planned College Major': 'Academics.Data.PlannedCollegeMajor',
                'GPA Verified': {
                    field: 'Academics.GPAVerified',
                    callback: (value) => {
                        if (value) {
                            return true;
                        }

                        return false;
                    }
                },
                'Academics Status': {
                    field: 'Academics.Status',
                    callback: (value) => {
                        if (value === 0) {
                            return "Not Started";
                        }
                        if (value === 1) {
                            return "Draft";
                        }
                        if (value === 2) {
                            return "Complete";
                        }

                        return "";
                    }
                },

                // Resume Section
                'Resume File Id': 'Resume.ResumeFileId',
                'Resume Assignment Type': {
                    field: 'Resume.AssignmentType',
                    callback: (value) => {
                        if (value === 1) {
                            return "Essay";
                        }
                        if (value === 2) {
                            return "Video";
                        }

                        return "";
                    }
                },
                'Resume Status': {
                    field: 'Resume.Status',
                    callback: (value) => {
                        if (value === 0) {
                            return "Not Started";
                        }
                        if (value === 1) {
                            return "Draft";
                        }
                        if (value === 2) {
                            return "Complete";
                        }

                        return "";
                    }
                },
                'Topic1 Type': 'Resume.Topic1Type',
                'Topic2 Type': 'Resume.Topic2Type',

                // Signature Section
                'Authorization Type': 'Signature.AuthorizationType',
                'Signature': 'Signature.Signature',

                // AdditionalInformation Section
                'Referrer': 'AdditionalInformation.Data.Referrer',
                'Social Urls': 'AdditionalInformation.Data.SocialUrls',
                'Survey OptIn': 'AdditionalInformation.Data.SurveyOptIn',

                // Extra fields
                'Admin Notes': 'AdminNotes',
                'Round 1': 'Round1',
                'Round 2': 'Round2',
                'Round 3': 'Round3',
                'Round 1 Score': {
                    callback: (value) => {
                        var roundScore = null;
                        if (value.Round1) {
                            value.Scores.forEach(function (score) {
                                if (score.Round === 1 && score.ScoreTypes !== null) {
                                    roundScore += score.Score;
                                }
                            });
                        }
                        return roundScore;
                    }
                },
                'Round 2 Score': {
                    callback: (value) => {
                        var roundScore = null;
                        if (value.Round2) {
                            value.Scores.forEach(function (score) {
                                if (score.Round === 2 && score.ScoreTypes !== null) {
                                    roundScore += score.Score;
                                }
                            });
                        }
                        return roundScore;
                    }
                },
                'Round 3 Score': {
                    callback: (value) => {
                        var roundScore = null;
                        if (value.Round3) {
                            value.Scores.forEach(function (score) {
                                if (score.Round === 3 && score.ScoreTypes !== null) {
                                    roundScore += score.Score;
                                    roundScore += score.Score;
                                }
                            });
                        }
                        return roundScore;
                    }
                },
                'Application Submitted': {
                    field: 'Submitted',
                    callback: (value) => {
                        if (value) {
                            return "Submitted";
                        } else {
                            return "Draft";
                        }
                    }
                },
                'Application Status': {
                    callback: (value) => {
                        if (value.Submitted) {
                            if (value.MemberVerified && value.Academics.GPAVerified && value.TranscriptReceived && value.References.Data.Reference1FileRecieved && value.References.Data.Reference2FileRecieved) {
                                return "Complete";
                            } else {
                                return "Submitted";
                            }
                        } else {
                            return "Draft";
                        }
                    }
                },
                'Submit Date': 'SubmitDate'
            }
        };
    },
    methods: {
        isMember: function (data) {
            if (data.Data.MemberVerified) {
                return true;
            }

            return false;
        },
        transcriptReceived: function (data) {
            if (data.TranscriptRecieved) {
                return true;
            }

            return false;
        },
        numberOfReferences: function (refObj) {
            var refNum = 0;

            if (refObj.Data.Reference1FileRecieved) {
                refNum++;
            }

            if (refObj.Data.Reference2FileRecieved) {
                refNum++;
            }

            return refNum;
        },
        schoolType: function (data) {
            if (data.Data.SchoolType === 1) {
                return "High School";
            }
            if (data.Data.SchoolType === 2) {
                return "College";
            }

            return "";
        },
        applicationStatus: function (app) {
            if (app.Submitted) {
                if (app.MemberVerified && app.Academics.GPAVerified && app.TranscriptReceived && app.References.Data.Reference1FileRecieved && app.References.Data.Reference2FileRecieved) {
                    return "Complete";
                } else {
                    return "Submitted";
                }
            } else {
                return "Draft";
            }
        },
        assignmentType: function (data) {
            if (data.AssignmentType === 1) {
                return "Essay";
            }
            if (data.AssignmentType === 2) {
                return "Video";
            }

            return "";
        },
        roundScore: function (application) {
            var vm = this;
            var roundScore = null;

            application.Scores.forEach(function (score) {
                if (score.Round === vm.round && score.ScoreTypes !== null) {
                    roundScore += score.Score;
                }
            });

            return roundScore;
        },
        rounds: function (data) {
            var rounds = [];

            if (data.Round1) {
                rounds.push("1");
            }
            if (data.Round2) {
                rounds.push("2");
            }
            if (data.Round3) {
                rounds.push("3");
            }

            var result = rounds.join(", ");

            return result;
        },

        setTeam: function () {
            var vm = this;

            axios.post(vm.setTeamUri, {
                teamIds: vm.assignedTeams,
                round: vm.round,
                applications: vm.selectedApplications
            }).then(response => {
                if (response.data) {
                    vm.resetGrid();
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },

        removeTeam: function () {
            var vm = this;

            axios.post(vm.removeTeamUri, {
                teamIds: vm.removedTeams,
                round: vm.round,
                applications: vm.selectedApplications
            }).then(response => {
                if (response.data) {
                    vm.resetGrid();
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },

        setRounds: function () {
            var vm = this;

            axios.post(vm.updateRoundsUri, {
                applications: vm.selectedApplications,
                round: vm.assignedRounds[0],
                value: true
            }).then(response => {
                if (response.data) {
                    vm.resetGrid();
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },

        removeRounds: function () {
            var vm = this;

            axios.post(vm.updateRoundsUri, {
                applications: vm.selectedApplications,
                round: vm.removedRounds[0],
                value: false
            }).then(response => {
                if (response.data) {
                    vm.resetGrid();
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },

        clickRound: function (round, e) {
            var vm = this;
            vm.round = round;
            vm.filteredTeams = vm.teams[round];

            var el = $(e);

            $('.scholarship-rounds-list .row a.active').removeClass('active');

            if (!el.hasClass('active')) {
                el.addClass('active');
            }

            vm.rowsSelected = false;
            vm.filteredResults.forEach(function (item) {
                item.Selected = false;
            });

            if (round === 0) {
                vm.allApplicants = true;
                vm.filteredResults = vm.results.slice();
            } else {
                vm.allApplicants = false;
                vm.filteredResults = vm.results.slice().filter(function (el) {
                    return el["Round" + round] === true;
                });
            }
            vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            vm.page = 1;
            vm.educationFilters = [];
            vm.statusFilters = [];
            vm.teamFilters = [];
            vm.selectedApplications = [];
            vm.assignedTeams = [];
            vm.removedTeams = [];
            vm.assignedRounds = [];
        },

        toggleAll: function () {
            var vm = this;
            var selectedApplications = [];

            vm.filteredResults.forEach(function (item) {
                if (vm.rowsSelected) {
                    item.Selected = false;
                } else {
                    item.Selected = true;

                    selectedApplications.push(item.ScholarshipApplicationId);
                }
            });

            if (vm.rowsSelected) {
                vm.results.forEach(function (item) {
                    item.Selected = false;
                });
            }

            vm.selectedApplications = selectedApplications;
            vm.rowsSelected = !vm.rowsSelected;
            vm.assignedTeams = [];
            vm.removedTeams = [];
            vm.assignedRounds = [];

            vm.$forceUpdate();
        },

        toggleRow: function (id) {
            var vm = this;
            var index = vm.filteredResults.findIndex((el => el.ScholarshipApplicationId === id));

            if (vm.filteredResults[index].Selected) {
                vm.filteredResults[index].Selected = false;
            } else {
                vm.filteredResults[index].Selected = true;
            }

            vm.rowsSelected = vm.filteredResults.some((el => el.Selected));

            vm.selectedApplications.push(id);
            vm.assignedTeams = [];
            vm.removedTeams = [];
            vm.assignedRounds = [];

            vm.$forceUpdate();
        },

        showEducationFilters: function () {
            var vm = this;

            if (vm.educationFilterShown) {
                vm.educationFilterShown = false;
            } else {
                vm.educationFilterShown = true;
            }
        },

        showStatusFilters: function () {
            var vm = this;

            if (vm.statusFilterShown) {
                vm.statusFilterShown = false;
            } else {
                vm.statusFilterShown = true;
            }
        },

        showTeamFilters: function () {
            var vm = this;

            if (vm.teamFilterShown) {
                vm.teamFilterShown = false;
            } else {
                vm.teamFilterShown = true;
            }
        },

        showTeams: function () {
            var vm = this;

            if (vm.showTeamAssign) {
                vm.showTeamAssign = false;
            } else {
                vm.showTeamAssign = true;
            }
        },

        showRemoveTeams: function () {
            var vm = this;

            if (vm.showTeamRemoval) {
                vm.showTeamRemoval = false;
            } else {
                vm.showTeamRemoval = true;
            }
        },

        showRounds: function () {
            var vm = this;

            if (vm.showRoundAssign) {
                vm.showRoundAssign = false;
            } else {
                vm.showRoundAssign = true;
            }
        },

        showRoundsRemove: function () {
            var vm = this;

            if (vm.showRoundRemove) {
                vm.showRoundRemove = false;
            } else {
                vm.showRoundRemove = true;
            }
        },

        clickPagination: function (pageNum) {
            var vm = this;

            vm.page = pageNum;
            var start = (pageNum - 1) * vm.pageSize;
            var end = start + vm.pageSize;
            vm.shownResults = vm.filteredResults.slice(start, end);
        },

        search: function () {
            var vm = this;

            vm.filterResults(true);

            if (vm.query !== "") {
                const preparedQuery = fz.prepareQuery(vm.query);
                const scores = {};

                vm.filteredResults = vm.filteredResults.map((item, index) => {
                    const scorableFields = [item.ScholarshipApplicationId.toString(), item.FirstName, item.LastName]
                        .map(toScore => fz.score(toScore, vm.query, { preparedQuery }));

                    scores[item.ScholarshipApplicationId] = Math.max(...scorableFields);

                    return item;
                })
                    .filter(item => scores[item.ScholarshipApplicationId] > 1)
                    .sort((a, b) => scores[b.ScholarshipApplicationId] - scores[a.ScholarshipApplicationId]);
            }

            vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            vm.page = 1;
        },
        clearQuery: function () {
            var vm = this;
            vm.query = "";

            vm.search();
        },
        filterResults: function (fromSearch) {
            var vm = this;

            vm.rowsSelected = false;
            vm.filteredResults.forEach(function (item) {
                item.Selected = false;
            });

            if (vm.round === 0) {
                vm.allApplicants = true;
                vm.filteredResults = vm.results.slice();
            } else {
                vm.allApplicants = false;
                vm.filteredResults = vm.results.slice().filter(function (el) {
                    return el["Round" + vm.round] === true;
                });
            }

            vm.filteredResults = vm.filteredResults.filter(function (item) {
                if (vm.educationFilters.includes("All")) {
                    vm.educationFilters = ["All"];
                    return true;
                }

                if (vm.educationFilters.length === 0 || vm.educationFilters.includes("All") || (item.Personal.Data.SchoolType && vm.educationFilters.includes(item.Personal.Data.SchoolType.toString()))) {
                    return true;
                }

                return false;
            });

            if (vm.round === 0) {
                vm.filteredResults = vm.filteredResults.filter(function (item) {
                    if (vm.statusFilters.length === 0 || vm.statusFilters.includes("All")) {
                        return true;
                    } else if (vm.statusFilters.includes("Draft") && !item.Submitted) {
                        return true;
                    } else if (vm.statusFilters.includes("Submitted") && item.Submitted && !(item.MemberVerified && item.Academics.GPAVerified && item.TranscriptReceived && item.References.Data.Reference1FileRecieved && item.References.Data.Reference2FileRecieved)) {
                        return true;
                    } else if (vm.statusFilters.includes("Complete") && item.Submitted && item.MemberVerified && item.Academics.GPAVerified && item.TranscriptReceived && item.References.Data.Reference1FileRecieved && item.References.Data.Reference2FileRecieved) {
                        return true;
                    }

                    return false;
                });
            } else {
                vm.filteredResults = vm.filteredResults.filter(function (item) {
                    if (vm.teamFilters.length > 0 && vm.teamFilters[vm.teamFilters.length - 1] === "All") {
                        vm.teamFilters = ["All"];
                    }

                    if (vm.teamFilters.length === 0 || vm.teamFilters.includes("All")) {
                        if (vm.teamFilters.length > 0 && vm.teamFilters[vm.teamFilters.length - 1] !== "All") {
                            var index = vm.teamFilters.indexOf("All");

                            vm.teamFilters.splice(index, 1);
                        } else {
                            return true;
                        }
                    }

                    var teamFound = item.Teams.some(el => vm.teamFilters.includes(el.TeamContentId.toString()));

                    return teamFound;
                });
            }

            if (!fromSearch) {
                vm.search();
            }

            vm.$forceUpdate();
        },

        sort: function (column) {
            var vm = this;

            if (column === "Id") {
                if (vm.idOrder === "asc") {
                    vm.idOrder = "dsc";
                } else if (vm.idOrder === "dsc" || vm.idOrder === "") {
                    vm.idOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("ScholarshipApplicationId", vm.idOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "FirstName") {
                if (vm.firstNameOrder === "asc") {
                    vm.firstNameOrder = "dsc";
                } else if (vm.firstNameOrder === "dsc" || vm.firstNameOrder === "") {
                    vm.firstNameOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("FirstName", vm.firstNameOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "LastName") {
                if (vm.lastNameOrder === "asc") {
                    vm.lastNameOrder = "dsc";
                } else if (vm.lastNameOrder === "dsc" || vm.lastNameOrder === "") {
                    vm.lastNameOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("LastName", vm.lastNameOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Member") {
                if (vm.memberOrder === "asc") {
                    vm.memberOrder = "dsc";
                } else if (vm.memberOrder === "dsc" || vm.memberOrder === "") {
                    vm.memberOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("MemberVerified", vm.memberOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Transcript") {
                if (vm.transcriptOrder === "asc") {
                    vm.transcriptOrder = "dsc";
                } else if (vm.transcriptOrder === "dsc" || vm.transcriptOrder === "") {
                    vm.transcriptOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("TranscriptReceived", vm.transcriptOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "References") {
                if (vm.referencesOrder === "asc") {
                    vm.referencesOrder = "dsc";
                } else if (vm.referencesOrder === "dsc" || vm.referencesOrder === "") {
                    vm.referencesOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("NumberOfReferences", vm.referencesOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Type") {
                if (vm.typeOrder === "asc") {
                    vm.typeOrder = "dsc";
                } else if (vm.typeOrder === "dsc" || vm.typeOrder === "") {
                    vm.typeOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("AssignmentType", vm.typeOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "School") {
                if (vm.schoolOrder === "asc") {
                    vm.schoolOrder = "dsc";
                } else if (vm.schoolOrder === "dsc" || vm.schoolOrder === "") {
                    vm.schoolOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("School", vm.schoolOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Teams") {
                if (vm.teamsOrder === "asc") {
                    vm.teamsOrder = "dsc";
                } else if (vm.teamsOrder === "dsc" || vm.teamsOrder === "") {
                    vm.teamsOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("TeamNames", vm.teamsOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Score") {
                if (vm.scoreOrder === "asc") {
                    vm.scoreOrder = "dsc";
                } else if (vm.scoreOrder === "dsc" || vm.scoreOrder === "") {
                    vm.scoreOrder = "asc";
                }

                var score = "Score" + vm.round;

                vm.filteredResults.sort(vm.compare(score, vm.scoreOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Rounds") {
                if (vm.roundsOrder === "asc") {
                    vm.roundsOrder = "dsc";
                } else if (vm.roundsOrder === "dsc" || vm.roundsOrder === "") {
                    vm.roundsOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("Rounds", vm.roundsOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            } else if (column === "Status") {
                if (vm.statusOrder === "asc") {
                    vm.statusOrder = "dsc";
                } else if (vm.statusOrder === "dsc" || vm.statusOrder === "") {
                    vm.statusOrder = "asc";
                }

                vm.filteredResults.sort(vm.compare("Submitted", vm.statusOrder));
                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);
            }
        },

        compare: function (column, order) {
            return function (a, b) {
                var result = 0;

                if (!a.hasOwnProperty(column) || !b.hasOwnProperty(column)) {
                    // property doesn't exist on either object
                    return 0;
                }

                var itemA = (typeof a[column] === 'string') ? a[column].toUpperCase() : a[column];
                var itemB = (typeof b[column] === 'string') ? b[column].toUpperCase() : b[column];

                if ((itemA === null || itemA === "") && (itemB !== null && itemB !== "")) {
                    result = 1;
                } else if ((itemA !== null && itemA !== "") && (itemB === null || itemB === "")) {
                    result = -1;
                } else if (itemA > itemB) {
                    result = 1;
                } else if (itemB > itemA) {
                    result = -1;
                }

                return order === "dsc" ? result * -1 : result;
            };
        },

        documentClick: function (e) {
            var vm = this;

            vm.showEducationFilters = false;
            vm.showStatusFilters = false;
            vm.showTeamFilters = false;
            vm.showRounds = false;
            vm.showRoundsRemove = false;
            vm.showTeams = false;
            vm.showRemoveTeams = false;
        },

        resetGrid: function () {
            var vm = this;

            axios.get(vm.getApplicantsUri).then(response => {
                vm.results = response.data.applications;

                if (vm.results === null) {
                    vm.results = [];
                }

                vm.results.forEach(function (item) {
                    item["Selected"] = false;
                });

                vm.allApplicants = true;

                vm.filteredResults = vm.results.slice();

                if (vm.round !== 0) {
                    vm.allApplicants = false;
                    vm.filteredResults = vm.results.slice().filter(function (el) {
                        return el["Round" + vm.round] === true;
                    });
                }

                vm.shownResults = vm.filteredResults.slice(0, vm.pageSize);

                vm.totalPages = Math.ceil(response.data.totalApplications / vm.pageSize);

                vm.resultsLoaded = true;
            }, error => {
                console.error(error);
            });

            vm.page = 1;
            vm.rowsSelected = false;
            vm.selectedApplications = [];
            vm.educationFilterShown = false;
            vm.educationFilters = [];
            vm.statusFilterShown = false;
            vm.statusFilters = [];
            vm.teamFilterShown = false;
            vm.teamFilters = [];
            vm.showAssignDropdowns = false;
            vm.assignedTeams = [];
            vm.showTeamAssign = false;
            vm.removedTeams = [];
            vm.showTeamRemoval = false;
            vm.assignedRounds = [];
            vm.showRoundAssign = false;
            vm.removedRounds = [];
            vm.showRoundRemove = false;

            vm.$forceUpdate();
        },

        downloadFullView: function () {
            var vm = this;

            var json = vm.getProcessedJson(vm.results, vm.json_fields);
            var data = vm.jsonToXLS(json);
            var blob = vm.base64ToBlob(data, "application/vnd.ms-excel");

            download(blob, "scholarship_applications.xls", "application/vnd.ms-excel");

            return vm.results;
        },
        downloadCurrentView: function () {
            var vm = this;

            var json = vm.getProcessedJson(vm.filteredResults, vm.json_fields);
            var data = vm.jsonToXLS(json);
            var blob = vm.base64ToBlob(data, "application/vnd.ms-excel");

            download(blob, "scholarship_applications.xls", "application/vnd.ms-excel");

            return vm.filteredResults;
        },
        jsonToXLS(data) {
            var vm = this;
            var xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${table}</table></body></html>';
            var xlsData = "<thead>";
            const colspan = Object.keys(data[0]).length;
            //Fields
            xlsData += "<tr>";
            for (var key in data[0]) {
                xlsData += "<th>" + key + "</th>";
            }
            xlsData += "</tr>";
            xlsData += "</thead>";
            //Data
            xlsData += "<tbody>";
            data.map(function (item, index) {
                xlsData += "<tr>";
                for (var key in item) {
                    var td = item[key] !== null ? item[key] : "";
                    xlsData += "<td>" + vm.valueReformattedForMultilines(td) + "</td>";
                }
                xlsData += "</tr>";
            });
            xlsData += "</tbody>";
            return xlsTemp.replace("${table}", xlsData).replace("${worksheet}", { type: String, default: "Sheet1" });
        },
        valueReformattedForMultilines(value) {
            if (typeof (value) === "string") return (value.replace(/\n/ig, "<br/>"));
            else return (value);
        },
        getProcessedJson(data, keys) {
            var vm = this;
            var newData = [];
            data.map(function (item, index) {
                var newItem = {};
                for (var label in keys) {
                    var property = keys[label];
                    newItem[label] = vm.getValue(property, item);
                }
                newData.push(newItem);
            });
            return newData;
        },
        getValue(key, item) {
            var vm = this;
            const field = typeof key !== "object" ? key : key.field;
            var indexes = typeof field !== "string" ? [] : field.split(".");
            var value = null;

            if (!field)
                value = item;
            else if (indexes.length > 1)
                value = vm.getValueFromNestedItem(item, indexes);
            else
                value = vm.parseValue(item[field]);

            if (key.hasOwnProperty('callback'))
                value = vm.getValueFromCallback(value, key.callback);

            return value;
        },
        getValueFromNestedItem(item, indexes) {
            var nestedItem = item;
            for (var index of indexes) {
                if (nestedItem)
                    nestedItem = nestedItem[index];
            }
            return this.parseValue(nestedItem);
        },
        getValueFromCallback(item, callback) {
            if (typeof callback !== "function")
                return null;
            const value = callback(item);
            return this.parseValue(value);
        },
        parseValue(value) {
            return value || value === 0 || typeof value === 'boolean'
                ? value
                : null;
        },
        base64ToBlob(data, mime) {
            var base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            var bstr = atob(base64);
            var n = bstr.length;
            var u8arr = new Uint8ClampedArray(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        getAppTeamsByRound: function (appTeams, team) {
            var teams = appTeams.filter(appTeam => {
                return appTeam.TeamContentId === team.TeamId;
            });

            return teams.length > 0;
        }
    },
    computed: {
        showAssignDropdowns: function () {
            return this.round !== 0 && this.rowsSelected;
        }
    },
    mounted: function () {
        var vm = this;

        axios.get(vm.getApplicantsUri).then(response => {
            vm.results = response.data.applications;

            if (vm.results === null) {
                vm.results = [];
            }

            vm.results.forEach(function (item) {
                item["Selected"] = false;
            });

            vm.filteredResults = vm.results.slice();
            vm.shownResults = vm.results.slice(0, vm.pageSize);

            vm.totalPages = Math.ceil(response.data.totalApplications / vm.pageSize);

            vm.resultsLoaded = true;
        }, error => {
            console.error(error);
        });

        //get teams
        axios.get(vm.getTeams1Uri)
            .then(response => {
                vm.teams[1] = response.data;
            }, error => {
                console.error(error);
            })
            .then(() => {
                axios.get(vm.getTeams2Uri)
                    .then(response => {
                        vm.teams[2] = response.data;
                    }, error => {
                        console.error(error);
                    });
            })
            .then(() => {
                axios.get(vm.getTeams3Uri)
                    .then(response => {
                        vm.teams[3] = response.data;
                    }, error => {
                        console.error(error);
                    });
            });

    },
    destroyed: function () {
        var vm = this;

        //document.removeEventListener('click', vm.documentClick);
    }
};