$(document).ready(function () {

    $('.form-upload').on('click', function (e) {
        $(this).siblings("input.FormFileUpload__Input").click();
        e.preventDefault();
    });

    $('#form-nav-prev').on('click', function () {
        $(this).siblings("button.btnPrev").click();
        var step = $('#customRange1').val();
        $('#customRange1').val(step - 1);
    });

    $('#form-nav-next').on('click', function () {
        $(this).siblings("button.btnNext").click();
        var step = parseInt($('#customRange1').val());
        $('#customRange1').val(step + 1);
    });

    $('.FormFileUpload__Input').change(function () {
        var fullPath = $(this).val();

        var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')) + 1;
        var fileName = fullPath.substring(startIndex);

        var fileLabelId = $(this).attr('id') + "-file-name";
        var fileLabel = $('#' + fileLabelId);

        fileLabel.html(fileName);
    });

    $('.FormDateTime__Input').after("<div class='form-birth-icon'><a class='date-icon' title='open date picker'><span class='fa fa-calendar' aria-hidden='true'></i></a></div>");


    $('.custom-range').on('input', function () {
        $(this).next('.range-current-value').html(this.value);
    });

    $('.forms-block .prettydropdown.form-dropdown').addClass('col-sm-6');

    var fieldRows = $('.forms-left-col').children('.Form__Element, button[type="submit"], input.reset-btn');

    fieldRows.each(function (index) {
        var row = index + 1;
        $(this).css("grid-row", row.toString());
    });

    var imageChoices = $('.forms-image-choice').children('.forms-image-list');
    var row = 0;

    imageChoices.each(function (index) {
        var column = 1;
        var mod = (index + 1) % 2;

        if (mod === 0) {
            column = 2;
        } else {
            row++;
        }

        $(this).css("grid-row", row.toString());
        $(this).css("grid-column", column.toString());
    });
});