import "jquery-validation";
import "jquery-validation/dist/additional-methods";

$(document).ready(function () {
    var validator = null;
    var validated = false;

    var saveAndContinue = function (data, saveUrl, nextStepUrl, form, validateObj) {
        //Validate Form
        validator = form.validate(validateObj);
        validated = true;
        var validatePassed = form.valid();

        if (validatePassed) {
            $.ajax({
                url: saveUrl,
                dataType: "json",
                type: 'Post',
                data: data,
                success: function (saved) {
                    if (saved) {
                        document.location.assign(document.location.origin + nextStepUrl);
                    }
                },
                error: function (error, exception) {
                    console.log("An error occurred");
                }
            });
        }
    };

    var saveAndContinueMultiPart = function (data, saveUrl, nextStepUrl, form, validateObj) {
        //Validate Form
        validator = form.validate(validateObj);
        validated = true;
        var validatePassed = form.valid();

        if (validatePassed) {
            $.ajax({
                url: saveUrl,
                cache: false,
                contentType: false,
                processData: false,
                type: 'Post',
                data: data,
                success: function (response) {
                    if (response.Success) {
                        document.location.assign(document.location.origin + nextStepUrl);
                    } else {
                        showErrorMessage(response.ErrorType);
                    }
                },
                error: function (error, exception) {
                    console.log("An error occurred");
                }
            });
        }
    };

    var save = function (data, saveUrl, form, validateObj) {
        //Validate Form
        validator = form.validate(validateObj);
        validated = true;
        var validatePassed = form.valid();

        if (validatePassed) {
            $.ajax({
                url: saveUrl,
                dataType: "json",
                type: 'Post',
                data: data,
                success: function (saved) {
                    if (saved) {
                        console.log("data saved");
                        window.location.href = window.location.href;
                    }
                },
                error: function (error, exception) {
                    console.log("An error occurred");
                }
            });
        }
    };

    var saveMultiPart = function(data, saveUrl, form, validateObj) {
        //Validate Form
        validator = form.validate(validateObj);
        validated = true;
        var validatePassed = form.valid();

        if (validatePassed) {
            $.ajax({
                url: saveUrl,
                cache: false,
                contentType: false,
                processData: false,
                type: 'Post',
                data: data,
                success: function(response) {
                    if (response.Success) {
                        console.log("data saved");
                        window.location.href = window.location.href;
                    } else {
                        showErrorMessage(response.ErrorType);
                    }
                },
                error: function(error, exception) {
                    console.log("An error occurred");
                }
            });
        }
    };

    var customFormSubmit = function(e, continueValidate, saveValidate, saveUrl) {
        var button = $(e.target);
        var form = button.closest('form');
        var formData = form.serialize();

        var formValues = formData.split('&');
        var formFields = {};
        var continueSubmit = false;
        var nextStepUrl = "";

        formValues.forEach(function(item) {
            var keyValue = item.split('=');

            if (keyValue[0] === "NextStepUrl") {
                nextStepUrl = decodeURIComponent(keyValue[1]);
            } else {
                formFields[keyValue[0]] = decodeURIComponent(keyValue[1]);
            }

            var submitButton = button.attr('name');
            if (submitButton === "SaveAndContinue") {
                continueSubmit = true;
            }
        });

        formFields["Continue"] = continueSubmit;

        if (validated) {
            validator.resetForm();

            form.removeData('validator');

            validated = false;
        }

        $.validator.setDefaults({
            ignore: []
        });

        if (continueSubmit) {
            saveAndContinue(formFields, saveUrl, nextStepUrl, form, continueValidate);
        } else {
            save(formFields, saveUrl, form, saveValidate);
        }
    };

    var multiPartCustomFormSubmit = function (e, continueValidate, saveValidate, saveUrl, fileUploadIds) {
        var button = $(e.target);
        var form = button.closest('form');
        var formData = new FormData();
        var serialData = form.serialize();

        var formValues = serialData.split('&');
        var continueSubmit = false;
        var nextStepUrl = "";

        formValues.forEach(function(item) {
            var keyValue = item.split('=');

            if (keyValue[0] === "NextStepUrl") {
                nextStepUrl = decodeURIComponent(keyValue[1]);
            } else {
                formData.append(keyValue[0], decodeURIComponent(keyValue[1]));
            }
        });

        var submitButton = button.attr('name');
        if (submitButton === "SaveAndContinue") {
            continueSubmit = true;
        }

        //commented code was preventing student from saving and sending refereces
        if (fileUploadIds !== null && fileUploadIds !== undefined) {
            fileUploadIds.forEach(function(id) {
                var element = $('#' + id);

                if (element.length) {
                    var files = element.get(0).files;

                    if (files[0] !== null && files[0] !== undefined) {
                        formData.append(element.attr("name"), files[0]);
                    }
                }
            });
        }

        formData.append("Continue", continueSubmit);

        if (validated) {
            validator.resetForm();

            form.removeData('validator');

            validated = false;
        }

        $.validator.setDefaults({
            ignore: []
        });

        if (continueSubmit) {
            saveAndContinueMultiPart(formData, saveUrl, nextStepUrl, form, continueValidate);
        } else {
            saveMultiPart(formData, saveUrl, form, saveValidate);
        }
    };

    var showErrorMessage = function(errorType) {
        if (errorType === 2) {
            var container = $('#ResumeFile').closest(".validation-container");
            container.append("<label id='ResumeFile-error' class='error' for='ResumeFile'>File must be 25MB or less</label>");
        } else if (errorType === 3) {
            var container = $('#Topic1DocFile').closest(".validation-container");
            container.append("<label id='Topic1DocFile-error' class='error' for='Topic1DocFile'>File must be 25MB or less</label>");
        } else if (errorType === 4) {
            var container = $('#Topic1VideoFile').closest(".validation-container");
            container.append("<label id='Topic1VideoFile-error' class='error' for='Topic1VideoFile'>File must be 50MB or less</label>");
        } else if (errorType === 5) {
            var container = $('#Topic2DocFile').closest(".validation-container");
            container.append("<label id='Topic2DocFile-error' class='error' for='Topic2DocFile'>File must be 25MB or less</label>");
        } else if (errorType === 6) {
            var container = $('#Topic2VideoFile').closest(".validation-container");
            container.append("<label id='Topic2VideoFile-error' class='error' for='Topic2VideoFile'>File must be 50MB or less</label>");
        } else if (errorType === 7) {
            var container = $('#Reference1File').closest(".validation-container");
            container.append("<label id='Reference1File-error' class='error' for='Reference1File'>File must be 25MB or less</label>");
        } else if (errorType === 8) {
            var container = $('#Reference2File').closest(".validation-container");
            container.append("<label id='Reference2File-error' class='error' for='Reference2File'>File must be 25MB or less</label>");
        } else if (errorType === 9) {
            var container = $('#PhotoFile').closest(".validation-container");
            container.append("<label id='PhotoFile-error' class='error' for='PhotoFile'>File must be 25MB or less</label>");
        } else if (errorType === 10) {
            var container = $('#ReferenceFile').closest(".validation-container");
            container.append("<label id='ReferenceFile-error' class='error' for='ReferenceFile'>File must be 25MB or less</label>");
        } else if (errorType === 11) {
            var container = $('#ParentalReleaseFile').closest(".validation-container");
            container.append("<label id='ParentalReleaseFile-error' class='error' for='ParentalReleaseFile'>File must be 25MB or less</label>");
        } else if (errorType === 12) {
            $('#references-error-message').text("First and last name must be provided before sending emails");
        } else {
            console.log("Unspecified error occurred");
        }
    };

    var requiredMultipleDependency = function(check1, check2) {
        var result = false;

        if ($(check1).length && $(check2).length) {
            result = true;
        }

        return result;
    };

    $('#personal-information-form input[type=submit]').click(function (e) {
        e.preventDefault();

        $.validator.addMethod("dateFormat", function (value, element) {
            return this.optional(element) || /^(0[1-9]|10|11|12)\/(1|2)[0-9]{3}$/.test(value);
        }, "Date must be in the format MM/YYYY");

        $.validator.addMethod("zipCode", function (value, element) {
            return this.optional(element) || /^([0-9]{5})(?:-[0-9]{4})?$/.test(value);
        }, "Please enter a valid zip code");

        var continueValidate = {
            rules: {
                FirstName: "required",
                LastName: "required",
                DateOfBirth: {
                    required: true,
                    date: true
                },
                DeliveryType: {
                    required: "#parental-auth-delivery-section:visible"
                },
                ParentalReleaseFile: {
                    required: requiredMultipleDependency("#parental-auth-upload-section:visible", "#upload-delivery:checked"),
                    extension: "doc|docx|pdf"
                },
                PhoneNumber: {
                    required: true,
                    phoneUS: true
                },
                CurrentEducation: "required",
                GraduationDate: {
                    required: true,
                    dateFormat: true
                },
                Address1: "required",
                City: "required",
                State: "required",
                ZipCode: {
                    required: true,
                    zipCode: true
                },
                PreviousWinner: "required",
                SsnLastFour: {
                    required: true,
                    digits: true,
                    maxlength: 4,
                    minlength: 4
                },
                MembershipConfirmation: "required"
            },
            messages: {
                FirstName: "First Name is required",
                LastName: "Last Name is required",
                DateOfBirth: {
                    required: "Date of Birth is required"
                },
                PhoneNumber: {
                    required: "Phone Number is required",
                    phoneUS: "Please enter a valid phone number"
                },
                CurrentEducation: "Please select your current education level",
                GraduationDate: {
                    required: "Graduation Date is required"
                },
                Address1: "Address 1 is required",
                City: "City is required",
                State: "State is required",
                ZipCode: {
                    required: "Zip Code is required"
                },
                PreviousWinner: "Please indicate if you have previously won an AACU scholarship",
                SsnLastFour: {
                    required: "Last four digits of SSN is required",
                    digits: "Please enter four digits"
                },
                MembershipConfirmation: "Please confirm that you are an eligible AACU member"
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                DateOfBirth: {
                    date: true
                },
                PhoneNumber: {
                    phoneUS: true
                },
                GraduationDate: {
                    dateFormat: true
                },
                ZipCode: {
                    zipCode: true
                },
                SsnLastFour: {
                    digits: true,
                    maxlength: 4,
                    minlength: 4
                }
            },
            messages: {
                PhoneNumber: {
                    phoneUS: "Please enter a valid phone number"
                },
                SsnLastFour: {
                    digits: "Please enter four digits"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/savePersonalInfo";

        var fileUploadIds = [
            "ParentalReleaseFile"
        ];

        multiPartCustomFormSubmit(e, continueValidate, saveValidate, saveUrl, fileUploadIds);
    });

    $('#references-form input[type=submit]').click(function (e) {
        e.preventDefault();

        $.validator.addMethod("emailRegex", function (value, element) {
            return this.optional(element) || /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(value);
        }, "Please enter a valid email address");

        var continueValidate = {
            rules: {
                Reference1Name: "required",
                Reference1Email: {
                    required: true,
                    emailRegex: true
                },
                Reference1File: {
                    extension: "doc|docx|pdf"
                },
                Reference2Name: "required",
                Reference2Email: {
                    required: true,
                    emailRegex: true
                },
                Reference2File: {
                    extension: "doc|docx|pdf"
                }
            },
            messages: {
                Reference1Name: {
                    required: "Name is required"
                },
                Reference1Email: {
                    required: "Email is required",
                },
                Reference1File: {
                    extension: "doc|docx|pdf"
                },
                Reference2Name: {
                    required: "Name is required"
                },
                Reference2Email: {
                    required: "Email is required",
                },
                Reference2File: {
                    extension: "doc|docx|pdf"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                Reference1Email: {
                    emailRegex: true
                },
                Reference2Email: {
                    emailRegex: true
                }
            },
            messages: {

            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveReferences";

        var fileUploadIds = [
            "Reference1File",
            "Reference2File"
        ];

        multiPartCustomFormSubmit(e, continueValidate, saveValidate, saveUrl, fileUploadIds);
    });

    $('#transcripts-request-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                TranscriptsRequested: "required"
            },
            messages: {
                TranscriptsRequested: {
                    required: "You must request your transcripts to be sent to continue"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveTranscriptsRequest";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });

    $('#academics-form input[type=submit]').click(function (e) {
        e.preventDefault();

        $.validator.addMethod("gpaDecimal", function (value, element) {
            return this.optional(element) || /^\d+(\.\d{1,2})?$/.test(value);
        }, "Please enter a valid number limited to 2 decimal places");

        var continueValidate = {
            rules: {
                CollegeName: "required",
                Major: "required",
                CollegeGPAScore: {
                    required: true,
                    gpaDecimal: true
                },
                CollegeGPAScale: {
                    required: true,
                    gpaDecimal: true
                },
                HighschoolName: "required",
                HasSAT: {
                    require_from_group: [1, ".score-type"]
                },
                HasACT: {
                    require_from_group: [1, ".score-type"]
                },
                HasUnweighted: {
                    require_from_group: [1, ".score-type"]
                },
                HasWeighted: {
                    require_from_group: [1, ".score-type"]
                },
                SATScore: {
                    required: "#show-sat:checked",
                    gpaDecimal: true
                },
                SATScale: {
                    required: "#show-sat:checked",
                    gpaDecimal: true
                },
                ACTScore: {
                    required: "#show-act:checked",
                    gpaDecimal: true
                },
                UnweightedGPAScore: {
                    required: "#show-unweighted:checked",
                    gpaDecimal: true
                },
                UnweightedGPAScale: {
                    required: "#show-unweighted:checked",
                    gpaDecimal: true
                },
                WeightedGPAScore: {
                    required: "#show-weighted:checked",
                    gpaDecimal: true
                },
                WeightedGPAScale: {
                    required: "#show-weighted:checked",
                    gpaDecimal: true
                },
                PlannedCollege: "required",
                PlannedMajor: "required"
            },
            messages: {
                CollegeName: {
                    required: "College Name is required"
                },
                Major: {
                    required: "Major is required"
                },
                CollegeGPAScore: {
                    required: "College GPA is required"
                },
                CollegeGPAScale: {
                    required: "College GPA scale is required"
                },
                HighschoolName: {
                    required: "Highschool Name is required"
                },
                HasSAT: {
                    require_from_group: "Please select at least one score type to provide"
                },
                HasACT: {
                    require_from_group: "Please select at least one score type to provide"
                },
                HasUnweighted: {
                    require_from_group: "Please select at least one score type to provide"
                },
                HasWeighted: {
                    require_from_group: "Please select at least one score type to provide"
                },
                SATScore: {
                    required: "SAT Score is required"
                },
                SATScale: {
                    required: "SAT Scale is required"
                },
                ACTScore: {
                    required: "ACT Score is required"
                },
                UnweightedGPAScore: {
                    required: "Unweighted Score is required"
                },
                UnweightedGPAScale: {
                    required: "Unweighted Scale is required"
                },
                WeightedGPAScore: {
                    required: "Weighted Score is required"
                },
                WeightedGPAScale: {
                    required: "Weighted Scale is required"
                },
                PlannedCollege: {
                    required: "Planned College/School is required"
                },
                PlannedMajor: {
                    required: "Planned Major is required"
                }
            },
            groups: {
                ScoreTypes: "HasSAT HasACT HasUnweighted HasWeighted"
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                CollegeGPAScore: {
                    gpaDecimal: true
                },
                CollegeGPAScale: {
                    gpaDecimal: true
                },
                SATScore: {
                    gpaDecimal: true
                },
                SATScale: {
                    gpaDecimal: true
                },
                ACTScore: {
                    gpaDecimal: true
                },
                UnweightedGPAScore: {
                    gpaDecimal: true
                },
                UnweightedGPAScale: {
                    gpaDecimal: true
                },
                WeightedGPAScore: {
                    gpaDecimal: true
                },
                WeightedGPAScale: {
                    gpaDecimal: true
                }
            },
            groups: {
                ScoreTypes: "HasSAT HasACT HasUnweighted HasWeighted"
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveAcademics";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });

    $('#scholarship-assignment-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                Resume: {
                    required: "#resume-upload:visible",
                    extension: "doc|docx|pdf"
                },
                AssignmentType: "required",
                Topic1Type: "required",
                Topic1DocFile: {
                    required: "#topic1-doc-upload:visible",
                    extension: "doc|docx|pdf"
                },
                Topic1VideoFile: {
                    required: "#topic1-video-upload:visible",
                    extension: "mp4|mov|wmv"
                },
                Topic2Type: "required",
                Topic2DocFile: {
                    required: "#topic2-doc-upload:visible",
                    extension: "doc|docx|pdf"
                },
                Topic2VideoFile: {
                    required: "#topic2-video-upload:visible",
                    extension: "mp4|mov|wmv"
                },
                PhotoFile: {
                    required: "#photo-upload:visible",
                    extension: "jpg|jpeg|png|gif"
                }
            },
            messages: {
                Resume: {
                    required: "Resume is required",
                    extension: "Please select an allowed file type"
                },
                AssignmentType: {
                    required: "Please select an upload type"
                },
                Topic1Type: {
                    required: "Please select a financial essay topic"
                },
                Topic1DocFile: {
                    required: "Financial essay is required",
                    extension: "Please select an allowed file type"
                },
                Topic1VideoFile: {
                    required: "Financial essay is required",
                    extension: "Please select an allowed file type"
                },
                Topic2Type: {
                    required: "Please select a personal essay topic"
                },
                Topic2DocFile: {
                    required: "Personal essay is required",
                    extension: "Please select an allowed file type"
                },
                Topic2VideoFile: {
                    required: "Personal essay is required",
                    extension: "Please select an allowed file type"
                },
                Photo: {
                    required: "Photo is required",
                    extension: "Allowed file types are .jpg, .jpeg, .png, and .gif"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                Resume: {
                    extension: "doc|docx|pdf"
                },
                Topic1DocFile: {
                    extension: "doc|docx|pdf"
                },
                Topic1VideoFile: {
                    extension: "mp4|mov|wmv"
                },
                Topic2DocFile: {
                    extension: "doc|docx|pdf"
                },
                Topic2VideoFile: {
                    extension: "mp4|mov|wmv"
                },
                Photo: {
                    extension: "jpg|jpeg|png|gif"
                }
            },
            messages: {
                Resume: {
                    extension: "Please select an allowed file type"
                },
                Topic1DocFile: {
                    extension: "Please select an allowed file type"
                },
                Topic1VideoFile: {
                    extension: "Please select an allowed file type"
                },
                Topic2DocFile: {
                    extension: "Please select an allowed file type"
                },
                Topic2VideoFile: {
                    extension: "Please select an allowed file type"
                },
                Photo: {
                    extension: "Allowed file types are .jpg, .jpeg, .png, and .gif"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveAssignmentUploads";

        var fileUploadIds = [
            "ResumeFile",
            "Topic1DocFile",
            "Topic1VideoFile",
            "Topic2DocFile",
            "Topic2VideoFile",
            "PhotoFile"
        ];

        multiPartCustomFormSubmit(e, continueValidate, saveValidate, saveUrl, fileUploadIds);
    });

    $('#sign-and-submit-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                ReleaseType: "required",
                ApplicantSignature: "required"
            },
            messages: {
                ReleaseType: {
                    required: "Please select a release option"
                },
                ApplicantSignature: {
                    required: "Signature is required"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/submitApplication";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });

    $('#additional-questions-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                
            },
            messages: {
                
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                
            },
            messages: {
                
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveAdditionalQuestions";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });

    $('#reference-upload-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                Reference: {
                    required: true,
                    extension: "doc|docx|pdf"
                }
            },
            messages: {
                Reference: {
                    extension: "Allowed file types are .doc, .docx, .pdf"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {
                Reference: {
                    required: true,
                    extension: "doc|docx|pdf"
                }
            },
            messages: {
                Reference: {
                    extension: "Allowed file types are .doc, .docx, .pdf"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveReferenceFile";

        var fileUploadIds = [
            "ReferenceFile"
        ];

        multiPartCustomFormSubmit(e, continueValidate, saveValidate, saveUrl, fileUploadIds);
    });

    $('#main-scoring-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {
                ResumeActivities: {
                    required: true
                },
                ResumeHonors: {
                    required: true
                },
                ResumeLeadership: {
                    required: true
                },
                EssayOrganization: {
                    required: true
                },
                EssayInsight: {
                    required: true
                },
                EssayOverall: {
                    required: true
                },
                Ref1Insight: {
                    required: true
                },
                Ref1Style: {
                    required: true
                },
                Ref1Recommendation: {
                    required: true
                },
                Ref2Insight: {
                    required: true
                },
                Ref2Style: {
                    required: true
                },
                Ref2Recommendation: {
                    required: true
                }
            },
            messages: {
                ResumeActivities: {
                    required: "Please select a score"
                },
                ResumeHonors: {
                    required: "Please select a score"
                },
                ResumeLeadership: {
                    required: "Please select a score"
                },
                EssayOrganization: {
                    required: "Please select a score"
                },
                EssayInsight: {
                    required: "Please select a score"
                },
                EssayOverall: {
                    required: "Please select a score"
                },
                Ref1Insight: {
                    required: "Please select a score"
                },
                Ref1Style: {
                    required: "Please select a score"
                },
                Ref1Recommendation: {
                    required: "Please select a score"
                },
                Ref2Insight: {
                    required: "Please select a score"
                },
                Ref2Style: {
                    required: "Please select a score"
                },
                Ref2Recommendation: {
                    required: "Please select a score"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {

            },
            messages: {

            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveFullScore";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });

    $('#offline-score-form input[type=submit]').click(function (e) {
        e.preventDefault();

        var continueValidate = {
            rules: {               
                OfflineScore: {
                    required: true
                }
            },
            messages: {
                OfflineScore: {
                    required: "Please enter a score"
                }
            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveValidate = {
            rules: {

            },
            messages: {

            },
            errorPlacement: function (error, element) {
                error.appendTo(element.closest(".validation-container"));
            }
        };

        var saveUrl = "/api/customForm/saveManualScore";

        customFormSubmit(e, continueValidate, saveValidate, saveUrl);
    });
});