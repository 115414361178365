import axios from 'axios';

export default {
    data: function () {
        return {
            events: [],
            categories: [],
            moreCategories: [],
            months: [],
            selectedCategory: "",
            selectedMobileCategory: "",
            selectedCategoryValue: "",
            selectedMonth: "",
            selectedMobileMonth: "",
            resultCharLimit: 0,
            categoriesLoaded: false,
            showFilterType: false,
            moreCategoriesActive: false,
            $monthDropdown: null,
            $categoryDropdown: null,
            getEventsUri: "/api/search/getEvents?category={0}&month={1}&resultCharLimit={2}"
        };
    },
    watch: {
        selectedMobileCategory: function (category) {
            this.setCategory(null, category);
        },
        selectedMobileMonth: function (month) {
            this.setMonth(null, month);
        }
    },
    methods: {
        moreFiltersClick: function () {
            this.showFilterType = !this.showFilterType;
            this.moreCategoriesActive = this.showFilterType;
        },

        getEvents: function () {
            var getEventsUri = this.stringFormat(this.getEventsUri, this.selectedCategory, this.selectedMonth, this.resultCharLimit);
            axios.get(getEventsUri).then(response => {

                console.log('events: ' + getEventsUri);

                this.events = response.data.Events;
                if (!this.categoriesLoaded) {
                    this.categories = response.data.Categories;
                    this.moreCategories = response.data.MoreCategories;

                    this.$monthDropdown = $('.select-list-dropdown-month').prettyDropdown({
                        customClass: 'dropdown form-dropdown arrow'
                    });

                    this.$categoryDropdown = $('.select-list-dropdown-category').prettyDropdown({
                        customClass: 'dropdown form-dropdown arrow'
                    });

                }

                this.months = response.data.Months;
                this.categoriesLoaded = true;

            }, error => {
                console.error(error);
            });
        },
        stringFormat: function () {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "gm");
                s = s.replace(reg, arguments[i + 1]);
            }

            return s;
        },
        setCategory: function (category) {
            this.selectedCategory = category;
            this.selectedCategoryValue = "";
            this.moreCategoriesActive = false;
            this.selectedMonth = "";
            $('.event-index a').removeClass('active');
            this.getEvents();
        },
        setMoreCategory: function (category) {
            this.selectedCategory = category;
            this.selectedCategoryValue = category;
            this.selectedMonth = "";
            $('.event-index a').removeClass('active');

            this.getEvents();
            this.moreFiltersClick();
            this.moreCategoriesActive = true;
        },
        setMonth: function (e, month) {
            $('.event-index a').removeClass('active');
            $(e.target).addClass('active');

            this.selectedMonth = month;
            this.getEvents();
        }
    },
    mounted: function () {
        this.resultCharLimit = this.$refs.eventSearch.dataset.charlimit;
        this.getEvents();
    },
    updated: function () {
        this.$nextTick(function () {
            if (this.$monthDropdown !== null && this.$monthDropdown !== undefined) {
                this.$monthDropdown.refresh();
            }
            if (this.$categoryDropdown !== null && this.$categoryDropdown !== undefined) {
                this.$categoryDropdown.refresh();
            }
        });
    },
    computed: {
        groupedMoreCategories() {
            //console.log('groupedMoreCategories');
            var grouped = [];
            var index = -1;
            for (let i = 0; i < this.moreCategories.length; i++) {
                if (i % 6 === 0) {
                    index++;
                    console.log(index);
                    grouped[index] = [];
                }
                console.log(index);
                grouped[index].push(this.moreCategories[i]);
            }
            return grouped;
        }
    }
};