$(document).ready(function () {

    $('.application-detail-tabs a').on('click', function () {
        $('.application-detail-tabs a').removeClass('active');

        $(this).addClass('active');
    });

    $('#application-details').on('click', function () {
        $('.application-panels .status-row-wrap').hide();

        $('#detail-tab-content').show();
    });

    $('#application-verification').on('click', function () {
        $('.application-panels .status-row-wrap').hide();

        $('#transcript-membership-verification').show();
    });

    $('#round-1-score').on('click', function () {
        $('.application-panels .status-row-wrap').hide();

        $('#round-1-score-content').show();
    });

    $('#admin-comments').on('focus', function () {
        $('.saved-message').hide();
    });

    $('#admin-comments-form').submit(function (e) {
        e.preventDefault();

        var form = $(this);
        var formData = form.serialize();
        var data = {};
        var fields = formData.split("&");

        fields.forEach(function (item) {
            var keyValue = item.split("=");

            data[keyValue[0]] = decodeURIComponent(keyValue[1]);
        });

        $.ajax({
            url: "/api/customForm/saveAdminComments",
            dataType: "json",
            type: 'Post',
            data: data,
            success: function (saved) {
                if (saved) {
                    $("#admin-comments-form .saved-message").show();
                    console.log("data saved");
                }
            },
            error: function (error, exception) {
                console.log("An error occurred");
            }
        });
    });

    $('#transcript-membership-verification-form').submit(function (e) {
        e.preventDefault();

        var form = $(this);
        var formData = form.serialize();
        var data = {};
        var fields = formData.split("&");

        fields.forEach(function (item) {
            var keyValue = item.split("=");

            data[keyValue[0]] = decodeURIComponent(keyValue[1]);
        });

        $.ajax({
            url: "/api/customForm/saveApplicationVerification",
            dataType: "json",
            type: 'Post',
            data: data,
            success: function (saved) {
                if (saved) {
                    $("#transcript-membership-verification-form .saved-message").show();
                    console.log("data saved");
                }
            },
            error: function (error, exception) {
                console.log("An error occurred");
            }
        });
    });

    $('#round-1-score-form').submit(function (e) {
        e.preventDefault();

        var form = $(this);
        var formData = form.serialize();
        var data = {};
        var fields = formData.split("&");

        fields.forEach(function (item) {
            var keyValue = item.split("=");

            data[keyValue[0]] = decodeURIComponent(keyValue[1]);
        });

        $.ajax({
            url: "/api/customForm/saveRound1Score",
            dataType: "json",
            type: 'Post',
            data: data,
            success: function (saved) {
                if (saved) {
                    console.log("data saved");
                    $('#round1-success-message').text("Successfully saved.");
                }
            },
            error: function (error, exception) {
                console.log("An error occurred");
            }
        });
    });
});